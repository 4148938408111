
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class RouteTitle extends Vue {
  @Prop(String) private label?: string;
  @Prop({ default: false }) private hideIcon!: boolean;
  
  get currentRouteLabel(): string {    
    return this.$route.meta && this.$route.meta.label ? this.$route.meta.label : '';
  }

  get pageLabel(): string {
    return this.label || this.currentRouteLabel;
  }

}
