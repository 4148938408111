import { Action, Module, VuexModule } from 'vuex-module-decorators';
import SIABffService from '@/services/sia-bff.service';
import { ConsolidatedByDayDTO } from '@/data/dto/consolidated-by-day.dto';
import { ConsolidatedByMonthDTO } from '@/data/dto/consolidated-by-month.dto';
import { ConsolidatedByYearDTO } from '@/data/dto/consolidated-by-year.dto';
import {
  ConsolidatedBySlotFiltersDTO,
  ConsolidatedBySlotByYearSearchParamsDTO,
  ConsolidatedBySlotByMonthSearchParamsDTO,
  ConsolidatedBySlotByDaySearchParamsDTO,
  ConsolidatedBySlotExportParams,
} from '@/data/dto/consolidated-by-slot.dto';
import { ConsolidatedByFortnightDTO } from "@/data/dto/consolidated-by-fortnight.dto";

@Module
export default class ConsolidatedBySlotModule extends VuexModule {
  bffService = new SIABffService();

  @Action
  getConsolidatedBySlotFilters(): Promise<ConsolidatedBySlotFiltersDTO> {
    return this.bffService
      .getConsolidatedBySlotFilters();
  }

  @Action
  getConsolidatedBySlotByYear(params: ConsolidatedBySlotByYearSearchParamsDTO): Promise<ConsolidatedByYearDTO | null> {
    return this.bffService
      .getConsolidatedBySlotByYear(params);
  }

  @Action
  getConsolidatedBySlotByMonth(params: ConsolidatedBySlotByMonthSearchParamsDTO): Promise<ConsolidatedByMonthDTO | null> {
    return this.bffService
      .getConsolidatedBySlotByMonth(params);
  }

  @Action
  getConsolidatedBySlotByFortnight(params: ConsolidatedBySlotByDaySearchParamsDTO): Promise<ConsolidatedByFortnightDTO | null> {
    return this.bffService
      .getConsolidatedBySlotByFortnight(params);
  }

  @Action
  getConsolidatedBySlotByDay(params: ConsolidatedBySlotByDaySearchParamsDTO): Promise<ConsolidatedByDayDTO | null> {
    return this.bffService
      .getConsolidatedBySlotByDay(params);
  }

  @Action
  getConsolidatedBySlotExcel(params: ConsolidatedBySlotExportParams): Promise<ArrayBuffer | null> {
    return this.bffService
      .getConsolidatedBySlotExcel(params);
  }
}
