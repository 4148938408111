import { RankedProgramDTO, RankingOfProgramsExportParamsDTO, RankingOfProgramsFiltersDTO, RankingOfProgramsSearchParamsDTO } from '../data/dto/ranking-of-programs';
import { HttpClient } from '@/core/http-client';
import { CredentialsDTO } from '@/data/dto/credentials.dto';
import { SessionDTO } from '@/data/dto/session.dto';
import { UserDTO } from '@/data/dto/user.dto';
import { GroupDTO } from '@/data/dto/group.dto';
import {
  GeneralConfigDTO,  tvNetworkResponse, marketResponse, NotificationsConfigDTO, DailyGridConfigDTO,
  DailyAudienceConfigDTO, MinuteByMinuteConfigDTO, ConsolidatedConfigDTO, RankingConfigDTO
} from '@/data/dto/config.dto';
import { DailyAudienceDTO, DailyAudienceSearchParamsDTO, DailyAudienceFiltersDTO, DailyAudienceExportParamsDTO } from '@/data/dto/daily-audience.dto';
import { SimultaneousProgramsDTO, SimultaneousProgramsSearchParamsDTO } from '@/data/dto/simultaneous-programs.dto';
import { SearchProgramsDTO, SearchProgramsSearchParamsDTO } from '@/data/dto/search-programs.dto';
import {
  DailyGridDTO, DailyGridSearchParamsDTO, DailyGridFiltersDTO,
  DailyGridExportParamsDTO, DailyGridAverageDTO
} from '@/data/dto/daily-grid.dto';
import {
  MinuteByMinuteDTO, MinuteByMinuteSearchParamsDTO, MinuteByMinuteFiltersDTO,
  MinuteByMinuteTvNetworkGridsDTO, MinuteByMinuteExportParamsDTO,
} from '@/data/dto/minute-by-minute.dto';
import {
  ConsolidatedByMarketDTO,
  ConsolidatedByMarketBySlotSearchParamsDTO,
  ConsolidatedByMarketBySlotFiltersDTO,
  ConsolidatedByMarketByProgramSearchParamsDTO,
  ConsolidatedByMarketByProgramFiltersDTO,
  ConsolidatedByMarketBySlotExportParams, ConsolidatedByMarketByProgramExportParams
} from '@/data/dto/consolidated-by-market.dto';
import {
  ConsolidatedBySlotFiltersDTO, ConsolidatedBySlotByYearSearchParamsDTO,
  ConsolidatedBySlotByMonthSearchParamsDTO, ConsolidatedBySlotByDaySearchParamsDTO, ConsolidatedBySlotExportParams,
} from '@/data/dto/consolidated-by-slot.dto';
import {
  ConsolidatedByProgramFiltersDTO,
  ConsolidatedByProgramByYearSearchParamsDTO,
  ConsolidatedByProgramByMonthSearchParamsDTO,
  ConsolidatedByProgramByDaySearchParamsDTO,
  ConsolidatedByProgramExportParams,
} from '@/data/dto/consolidated-by-program.dto';
import { ConsolidatedByYearDTO } from '@/data/dto/consolidated-by-year.dto';
import { ConsolidatedByMonthDTO } from '@/data/dto/consolidated-by-month.dto';
import { ConsolidatedByDayDTO } from '@/data/dto/consolidated-by-day.dto';
import { ResearchFilterDTO, FiltersByMarketSearchParams, FiltersByMarketResponse } from '@/data/dto/research-filter.dto';
import { getDailyAudienceMock } from "@/../tests/mocks/dailyAudienceMock";
import { ConsolidatedByFortnightDTO } from "@/data/dto/consolidated-by-fortnight.dto";
import {
  ProgramLastExhibitionDateDTO,
  ProgramLastExhibitionDateParamsDTO
} from "@/data/dto/program-last-exhibition-date.dto";
import { RankedTvNetworkDTO, RankingOfTvNetworksExportParamsDTO, RankingOfTvNetworksFiltersDTO, RankingOfTvNetworksSearchParamsDTO } from '@/data/dto/ranking-of-tv-networks.dto';

type UsersResponse = { users: UserDTO[] };
type GroupsResponse = { groups: GroupDTO[] };
type Permissions = { key: string; value: string };
type ConfigsResponse = { marketsId: ResearchFilterDTO[]; features: ResearchFilterDTO[]; };
type SimpleUserResponse = { name: string; email: string }[];

export interface ISIABffService {
  login(credentials: CredentialsDTO): Promise<SessionDTO>;
  authorize(): Promise<UserDTO>;
  getAllUsers(): Promise<UsersResponse>;
  getOneUser(email: string): Promise<GroupsResponse>;
  createUser(email: string, name: string, groups: string[]): Promise<void>;
  deleteUser(email: string): Promise<void>;
  updateUserStatus(email: string, active: boolean): Promise<void>;
  addUserToApplicationGroup(email: string, groups: string[]): Promise<void>;
  removeUserFromApplicationGroup(email: string, groupUUID: string): Promise<void>;
  getAllGroups(): Promise<GroupsResponse>;
  createGroup(name: string, description: string, permissions: Permissions[], hidden?: boolean): Promise<void>;
  updateGroupStatus(uuid: string, status: boolean): Promise<void>;
  updateGroup(uuid: string, description: string, name: string, permissions: Permissions[], hidden?: boolean): Promise<void>;
  deleteGroup(uuid: string): Promise<void>;
  getOneGroup(uuid: string): Promise<GroupDTO>;
  addMultipleUsersToApplicationGroup(uuid: string, users: string[]): Promise<void>;
  getAuthorizationConfigs(): Promise<ConfigsResponse>;
  getUsersFromGroup(uuid: string): Promise<SimpleUserResponse>;
  getGeneralConfigs(): Promise<GeneralConfigDTO>;
  updateGeneralConfigs(configs: GeneralConfigDTO): Promise<void>;
  getTvNetworkConfigs(): Promise<tvNetworkResponse>;
  updateTvNetworkConfigs(configs: tvNetworkResponse): Promise<void>;
  getMarketConfigs(): Promise<marketResponse>;
  updateMarketConfigs(configs: marketResponse): Promise<void>;
  getNotificationConfigs(user: string): Promise<NotificationsConfigDTO>;
  updateNotificationConfigs(user: string, configs: NotificationsConfigDTO): Promise<void>;
  getDailyGridConfigs(): Promise<DailyGridConfigDTO>;
  updateDailyGridConfigs(configs: DailyGridConfigDTO): Promise<void>;
  getDailyAudienceConfigs(): Promise<DailyAudienceConfigDTO>;
  updateDailyAudienceConfigs(configs: DailyAudienceConfigDTO): Promise<void>;
  getMinuteByMinuteConfigs(): Promise<MinuteByMinuteConfigDTO>;
  updateMinuteByMinuteConfigs(configs: MinuteByMinuteConfigDTO): Promise<void>;
  getConsolidatedConfigs(): Promise<ConsolidatedConfigDTO>;
  updateConsolidatedConfigs(configs: ConsolidatedConfigDTO): Promise<void>;
  getDailyAudience(params: DailyAudienceSearchParamsDTO): Promise<DailyAudienceDTO | null>;
  getDailyAudienceFilters(): Promise<DailyAudienceFiltersDTO>;
  getSimultaneousPrograms(params: SimultaneousProgramsSearchParamsDTO): Promise<SimultaneousProgramsDTO>;
  getDailyAudienceExcel(params: DailyAudienceExportParamsDTO): Promise<ArrayBuffer>;
  searchPrograms(params: SearchProgramsSearchParamsDTO): Promise<SearchProgramsDTO>;
  getProgramLastExhibitionDate(params: ProgramLastExhibitionDateParamsDTO): Promise<ProgramLastExhibitionDateDTO>;
  getDailyGrid(params: DailyGridSearchParamsDTO): Promise<DailyGridDTO | null>;
  getDailyGridFilters(): Promise<DailyGridFiltersDTO>;
  getDailyGridAverage(params: DailyGridSearchParamsDTO): Promise<DailyGridAverageDTO | null>;
  getDailyGridExcel(params: DailyGridExportParamsDTO): Promise<ArrayBuffer>;
  getMinuteByMinute(params: MinuteByMinuteSearchParamsDTO): Promise<MinuteByMinuteDTO | null>;
  getMinuteByMinuteFilters(): Promise<MinuteByMinuteFiltersDTO>;
  getMinuteByMinuteTvNetworkGrids(params: MinuteByMinuteSearchParamsDTO): Promise<MinuteByMinuteTvNetworkGridsDTO | null>;
  getMinuteByMinuteExcel(params: MinuteByMinuteExportParamsDTO): Promise<ArrayBuffer | null>;
  getConsolidatedByMarketBySlot(params: ConsolidatedByMarketBySlotSearchParamsDTO): Promise<ConsolidatedByMarketDTO | null>;
  getConsolidatedByMarketBySlotFilters(): Promise<ConsolidatedByMarketBySlotFiltersDTO>;
  getConsolidatedByMarketBySlotExcel(params: ConsolidatedByMarketBySlotExportParams): Promise<ArrayBuffer | null>;
  getConsolidatedByMarketByProgram(params: ConsolidatedByMarketByProgramSearchParamsDTO): Promise<ConsolidatedByMarketDTO | null>;
  getConsolidatedByMarketByProgramFilters(): Promise<ConsolidatedByMarketByProgramFiltersDTO>;
  getConsolidatedByMarketByProgramExcel(param: ConsolidatedByMarketByProgramExportParams): Promise<ArrayBuffer | null>;
  getConsolidatedBySlotFilters(): Promise<ConsolidatedBySlotFiltersDTO>;
  getConsolidatedBySlotByYear(params: ConsolidatedBySlotByYearSearchParamsDTO): Promise<ConsolidatedByYearDTO | null>;
  getConsolidatedBySlotByMonth(params: ConsolidatedBySlotByMonthSearchParamsDTO): Promise<ConsolidatedByMonthDTO | null>;
  getConsolidatedBySlotByFortnight(params: ConsolidatedBySlotByDaySearchParamsDTO): Promise<ConsolidatedByFortnightDTO | null>;
  getConsolidatedBySlotByDay(params: ConsolidatedBySlotByDaySearchParamsDTO): Promise<ConsolidatedByDayDTO | null>;
  getConsolidatedBySlotExcel(params: ConsolidatedBySlotExportParams): Promise<ArrayBuffer | null>;
  getConsolidatedByProgramFilters(): Promise<ConsolidatedByProgramFiltersDTO>;
  getConsolidatedByProgramByYear(params: ConsolidatedByProgramByYearSearchParamsDTO): Promise<ConsolidatedByYearDTO | null>;
  getConsolidatedByProgramByMonth(params: ConsolidatedByProgramByMonthSearchParamsDTO): Promise<ConsolidatedByMonthDTO | null>;
  getConsolidatedByProgramByDay(params: ConsolidatedByProgramByDaySearchParamsDTO): Promise<ConsolidatedByDayDTO | null>;
  getConsolidatedByProgramExcel(params: ConsolidatedByProgramExportParams): Promise<ArrayBuffer>;
  getRankingOfTvNetworksFilters(): Promise<RankingOfTvNetworksFiltersDTO>;
  getRankingOfTvNetworks(params: RankingOfTvNetworksSearchParamsDTO): Promise<RankedTvNetworkDTO[] | null>;
  getRankingOfTvNetworksExcel(params: RankingOfTvNetworksExportParamsDTO): Promise<ArrayBuffer>;
  getRankingOfProgramsFilters(): Promise<RankingOfProgramsFiltersDTO>;
  getRankingOfPrograms(params: RankingOfProgramsSearchParamsDTO): Promise<RankedProgramDTO[] | null>;
  getRankingOfProgramsExcel(params: RankingOfProgramsExportParamsDTO): Promise<ArrayBuffer | null>;
  getFiltersByMarket(marketId: FiltersByMarketSearchParams): Promise<FiltersByMarketResponse>;
  simulateUser(email: string): Promise<SessionDTO>;
}

export default class SIABffService extends HttpClient implements ISIABffService {
  constructor() {
    super(process.env.VUE_APP_SIA_BFF_HOST!);
  }

  //mocks
  _login = (credentials: CredentialsDTO): Promise<SessionDTO> => new Promise(res => res({ token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsZXhhbmRyZS50b3JxdWV0aV9nbG9iYWx0ZWNub2xvZ2lhQHByZXN0YWRvci5nbG9ibyIsImlhdCI6MTYzNDIzNjg4MiwiZXhwIjoxNjM2ODI4ODgyfQ.yrxSu5enr3ifysS_owZpanu9WxpIHxuGdznHdWKm0ps', refreshToken: '123' }));
  _authorize = (): Promise<UserDTO> => new Promise(res => res({
    email: 'fulano.ciclano@g.globo',
    name: 'Fulano',
    policies: {
      isPermissionManager: "true",
      isDailyAudience: "true",
      isDailyGrid: "true",
      isMinuteByMinute: "true",
      isConsolidatedByMarketBySlot: "true",
      isConsolidatedByMarketByProgram: "true",
      isConsolidatedBySlot: "true",
      isConsolidatedByProgram: "true",
    }
  }));

  // session
  login = (credentials: CredentialsDTO): Promise<SessionDTO> => this.client
    .post('/v1/users/current/auth', {
      code: credentials.code
    });

  authorize = (): Promise<UserDTO> => this.client
    .get('/v1/users/current/auth');

  // logout = (): Promise<void> => this.client
  //   .post('/v1/users/current/logout', {
  //     refreshToken: 'meutoken'
  //   });

  simulateUser = (email: string): Promise<SessionDTO> => this.client
    .post('/v1/users/simulate', {
      email
    });

  // users
  getAllUsers = (): Promise<UsersResponse> => this.client.get('/v1/users');
  getOneUser = (email: string) => this.client.get<GroupsResponse>(`/v1/users/${email}`);
  createUser = (email: string, name: string, groups: string[]) => this.client.post<void>('/v1/users', {
    email,
    name,
    groups,
  });
  deleteUser = (email: string) => this.client.delete<void>('/v1/users', {
    data: {
      email,
    },
  });
  updateUserStatus = (email: string, active: boolean) => this.client.put<void>('/v1/users', {
    email,
    active,
  });
  addUserToApplicationGroup = (email: string, groups: string[]) => this.client.put<void>(`/v1/users/${email}`, {
    groups,
  });
  removeUserFromApplicationGroup = (email: string, groupUUID: string) => this.client.delete<void>(`/v1/users/${email}`, {
    data: {
      groupUUID,
    },
  })

  // groups
  getAllGroups = () => this.client.get<GroupsResponse>('/v1/groups');
    createGroup = (name: string, description: string, permissions: Permissions[], hidden?: boolean) => this.client.post<void>('/v1/groups/', {
    name,
    description,
    permissions,
    hidden,
  });
  updateGroupStatus = (uuid: string, status: boolean) => this.client.put<void>('/v1/groups/', {
    uuid,
    status,
  });
  updateGroup = (uuid: string, name: string, description: string, permissions: Permissions[], hidden?: boolean) => this.client.put<void>(`/v1/groups/${uuid}`, {
    name,
    description,
    permissions,
    hidden,
  });
  deleteGroup = (uuid: string) => this.client.delete<void>(`/v1/groups/${uuid}`);
  getOneGroup = (uuid: string) => this.client.get<GroupDTO>(`/v1/groups/${uuid}`);
  addMultipleUsersToApplicationGroup = (uuid: string, users: string[]) => this.client.post<void>(`/v1/groups/${uuid}`, {
    users
  });
  getUsersFromGroup = (uuid:string): Promise<SimpleUserResponse> => this.client.get<SimpleUserResponse>(`v1/groups/${uuid}/show_users`);

  // configs
  getAuthorizationConfigs = () => this.client.get<ConfigsResponse>('/v1/authorization/configs');

  getGeneralConfigs = () => this.client.get<GeneralConfigDTO>('/v1/adm/generalconfigs');
  updateGeneralConfigs = (configs: GeneralConfigDTO) => this.client.put<void>('/v1/adm/generalconfigs', configs);

  getTvNetworkConfigs = () => this.client.get<tvNetworkResponse>('/v1/adm/tvnetworkconfigs');
  updateTvNetworkConfigs = (configs: tvNetworkResponse) => this.client.put<void>('/v1/adm/tvnetworkconfigs', configs);

  getMarketConfigs = () => this.client.get<marketResponse>('/v1/adm/marketconfigs');
  updateMarketConfigs = (configs: marketResponse) => this.client.put<void>('/v1/adm/marketconfigs', configs);

  getNotificationConfigs = (user: string) => this.client.get<NotificationsConfigDTO>(`/v1/adm/notificationconfigs/${user}`);
  updateNotificationConfigs = (user: string, configs: NotificationsConfigDTO) => this.client.put<void>(`/v1/adm/notificationconfigs/${user}`, configs);

  getDailyGridConfigs = () => this.client.get<DailyGridConfigDTO>('/v1/adm/dailygridconfigs');
  updateDailyGridConfigs = (configs: DailyGridConfigDTO) => this.client.put<void>('/v1/adm/dailygridconfigs', configs);

  getRankingConfigs = () => this.client.get<RankingConfigDTO>('/v1/adm/rankingconfigs');
  updateRankingConfigs = (configs: RankingConfigDTO) => this.client.put<void>('/v1/adm/rankingconfigs', configs);

  getDailyAudienceConfigs = () => this.client.get<DailyAudienceConfigDTO>('/v1/adm/dailyaudienceconfigs');
  updateDailyAudienceConfigs = (configs: DailyAudienceConfigDTO) => this.client.put<void>('/v1/adm/dailyaudienceconfigs', configs);

  getMinuteByMinuteConfigs = () => this.client.get<MinuteByMinuteConfigDTO>('/v1/adm/minutebyminuteconfigs');
  updateMinuteByMinuteConfigs = (configs: MinuteByMinuteConfigDTO) => this.client.put<void>('/v1/adm/minutebyminuteconfigs', configs);

  getConsolidatedConfigs = () => this.client.get<ConsolidatedConfigDTO>('/v1/adm/consolidatedconfigs');
  updateConsolidatedConfigs = (configs: ConsolidatedConfigDTO) => this.client.put<void>('/v1/adm/consolidatedconfigs', configs);

  // search programs
  searchPrograms = (params: SearchProgramsSearchParamsDTO) => this.client.post<SearchProgramsDTO>('v1/programs/search', params);

  getProgramLastExhibitionDate = (params: ProgramLastExhibitionDateParamsDTO) => this.client.post<ProgramLastExhibitionDateDTO>('v1/programs/lastexhibitiondate', params);

  // filters
  getFiltersByMarket = (params: FiltersByMarketSearchParams) => this.client.post<FiltersByMarketResponse>('v1/filters/filtersbymarket', params);

  //daily audience
  getDailyAudience = (params: DailyAudienceSearchParamsDTO) => this.client.post<DailyAudienceDTO | null>('/v1/dailyaudience', params);
  getDailyAudienceFilters = () => this.client.get<DailyAudienceFiltersDTO>('/v1/dailyaudience/filters');
  getSimultaneousPrograms = (params: SimultaneousProgramsSearchParamsDTO) => this.client.post<SimultaneousProgramsDTO>('v1/dailyaudience/simultaneousprograms', params);
  getDailyAudienceExcel = (params: DailyAudienceExportParamsDTO) => this.client.post<ArrayBuffer>('/v1/dailyaudience/exportexcel', params, { responseType: 'arraybuffer' });

  //daily grid
  getDailyGrid = (params: DailyGridSearchParamsDTO) => this.client.post<DailyGridDTO | null>('/v1/dailygrid', params);
  getDailyGridFilters = () => this.client.get<DailyGridFiltersDTO>('/v1/dailygrid/filters');
  getDailyGridAverage = (params: DailyGridSearchParamsDTO) => this.client.post<DailyGridAverageDTO | null>('/v1/dailygrid/average', params);
  getDailyGridExcel = (params: DailyGridExportParamsDTO) => this.client.post<ArrayBuffer>('/v1/dailygrid/exportexcel', params, { responseType: 'arraybuffer' });

  //minute by minute
  getMinuteByMinute = (params: MinuteByMinuteSearchParamsDTO) => this.client.post<MinuteByMinuteDTO | null>('/v1/minutebyminute', params);
  getMinuteByMinuteFilters = () => this.client.get<MinuteByMinuteFiltersDTO>('/v1/minutebyminute/filters');
  getMinuteByMinuteTvNetworkGrids = (params: MinuteByMinuteSearchParamsDTO) => this.client.post<MinuteByMinuteTvNetworkGridsDTO | null>('v1/minutebyminute/tvnetworkgrids', params);
  getMinuteByMinuteExcel= (params: MinuteByMinuteExportParamsDTO) => this.client.post<ArrayBuffer | null>('v1/minutebyminute/exportexcel', params, { responseType: 'arraybuffer' });

  //consolidated: by market - slot
  getConsolidatedByMarketBySlot = (params: ConsolidatedByMarketBySlotSearchParamsDTO) => this.client.post<ConsolidatedByMarketDTO | null>('/v1/consolidatedbymarketbyslot', params);
  getConsolidatedByMarketBySlotFilters = () => this.client.get<ConsolidatedByMarketBySlotFiltersDTO>('/v1/consolidatedbymarketbyslot/filters');
  getConsolidatedByMarketBySlotExcel = (params: ConsolidatedByMarketBySlotExportParams) => this.client.post<ArrayBuffer | null>('/v1/consolidatedbymarketbyslot/exportexcel', params, { responseType: 'arraybuffer' });

  //consolidated: by market - program
  getConsolidatedByMarketByProgram = (params: ConsolidatedByMarketByProgramSearchParamsDTO) => this.client.post<ConsolidatedByMarketDTO | null>('/v1/consolidatedbymarketbyprogram', params);
  getConsolidatedByMarketByProgramFilters = () => this.client.get<ConsolidatedByMarketByProgramFiltersDTO>('/v1/consolidatedbymarketbyprogram/filters');
  getConsolidatedByMarketByProgramExcel = (params: ConsolidatedByMarketByProgramExportParams) => this.client.post<ArrayBuffer | null>('/v1/consolidatedbymarketbyprogram/exportexcel', params, { responseType: 'arraybuffer' });
  //consolidated: by slot
  getConsolidatedBySlotFilters = () => this.client.get<ConsolidatedBySlotFiltersDTO>('/v1/consolidatedbyslot/filters');
  getConsolidatedBySlotByYear = (params: ConsolidatedBySlotByYearSearchParamsDTO) => this.client.post<ConsolidatedByYearDTO | null>('/v1/consolidatedbyslot/years', params);
  getConsolidatedBySlotByMonth = (params: ConsolidatedBySlotByMonthSearchParamsDTO) => this.client.post<ConsolidatedByMonthDTO | null>('/v1/consolidatedbyslot/months', params);
  getConsolidatedBySlotByFortnight = (params: ConsolidatedBySlotByDaySearchParamsDTO) => this.client.post<ConsolidatedByFortnightDTO | null>('/v1/consolidatedbyslot/fortnight', params);
  getConsolidatedBySlotByDay = (params: ConsolidatedBySlotByDaySearchParamsDTO) => this.client.post<ConsolidatedByDayDTO | null>('/v1/consolidatedbyslot/days', params);
  getConsolidatedBySlotExcel = (params: ConsolidatedBySlotExportParams) => this.client.post<ArrayBuffer | null>('/v1/consolidatedbyslot/exportexcel', params, { responseType: 'arraybuffer' });

  //consolidated: by program
  getConsolidatedByProgramFilters = () => this.client.get<ConsolidatedByProgramFiltersDTO>('/v1/consolidatedbyprogram/filters');
  getConsolidatedByProgramByYear = (params: ConsolidatedByProgramByYearSearchParamsDTO) => this.client.post<ConsolidatedByYearDTO | null>('/v1/consolidatedbyprogram/years', params);
  getConsolidatedByProgramByMonth = (params: ConsolidatedByProgramByMonthSearchParamsDTO) => this.client.post<ConsolidatedByMonthDTO | null>('/v1/consolidatedbyprogram/months', params);
  getConsolidatedByProgramByDay = (params: ConsolidatedByProgramByDaySearchParamsDTO) => this.client.post<ConsolidatedByDayDTO | null>('/v1/consolidatedbyprogram/days', params);
  getConsolidatedByProgramExcel = (params: ConsolidatedByProgramExportParams) => this.client.post<ArrayBuffer>('/v1/consolidatedbyprogram/exportexcel', params, { responseType: 'arraybuffer' });

  //ranking: by tv network
  getRankingOfTvNetworksFilters = () => this.client.get<RankingOfTvNetworksFiltersDTO>('v1/rankingoftvnetworks/filters');
  getRankingOfTvNetworks = (params: RankingOfTvNetworksSearchParamsDTO) => this.client.post<RankedTvNetworkDTO[] | null>('v1/rankingoftvnetworks', params);
  getRankingOfTvNetworksExcel = (params: RankingOfTvNetworksExportParamsDTO) => this.client.post<ArrayBuffer>('v1/rankingoftvnetworks/exportexcel', params, { responseType: 'arraybuffer' });

  getRankingOfProgramsFilters = () => this.client.get<RankingOfProgramsFiltersDTO>('v1/rankingofprograms/filters');
  getRankingOfPrograms = (params: RankingOfProgramsSearchParamsDTO) => this.client.post<RankedProgramDTO[] | null>('v1/rankingofprograms', params);
  getRankingOfProgramsExcel = (params: RankingOfProgramsExportParamsDTO) => this.client.post<ArrayBuffer | null>('v1/rankingofprograms/exportexcel', params, { responseType: 'arraybuffer' });
  //mocks
  _getDailyAudience = (params: DailyAudienceSearchParamsDTO) : Promise<DailyAudienceDTO> => new Promise(res => res(getDailyAudienceMock));

  _getDailyAudienceFilters = () => new Promise(res => res({
    markets: [
      { label: 'GSP - GRANDE SÃO PAULO', value:'GSP' },
      { label: 'PNC - PNT COMPLETO', value:'PNC' },
    ],
    targets: [
      { label: 'DOMICILIAR', value:'domiciliar' },
      { label: 'HOMENS', value:'h' },
      { label: 'MULHERES', value:'m' },
    ],
    tvNetworks: [
      { label: 'TV GLOBO', value:'GLOBO' },
      { label: 'REDE RECORD', value:'REC' },
      { label: 'SBT', value:'SBT' },
      { label: 'BANDEIRANTES', value:'BAND' },
    ],
    connectedTvsSumTypes: [
      { label: 'TL', value:'tl' },
      { label: 'TLE', value:'tle' },
    ],
    weekdays: [
      { label: 'DOMINGO', value: 'DOMINGO' },
      { label: 'SEGUNDA', value: 'SEGUNDA' },
      { label: 'TERÇA', value: 'TERCA' },
      { label: 'QUARTA', value: 'QUARTA' },
      { label: 'QUINTA', value: 'QUINTA' },
      { label: 'SEXTA', value: 'SEXTA' },
      { label: 'SÁBADO', value: 'SABADO' },
    ],
    programs: [
      // { label: 'JORNAL NACIONAL', value: 'JN' },
      // { label: 'MAIS VOCÊ', value: 'MAVO' },
    ],
    decimalPlaces: [
      { label: 'nenhuma', value: 0 },
      { label: 'uma', value: 1 },
      { label: 'duas', value: 2 },
    ],
    defaultValues: {
      market: 'GSP',
      target: 'domiciliar',
      mainTvNetwork: 'GLOBO',
      secondaryTvNetworks: ['REC', 'BAND'],
      connectedTvsSumType: 'tle',
      weekdays: ['DOMINGO','SEGUNDA','TERCA','QUARTA','QUINTA','SEXTA','SABADO'],
      program: '',
      startDate: '2021-01-31',
      endDate: '2021-01-31',
      share: false,
      // equivalentPeriod: false,
      // decimalPlaces: 0,
      // startTime: '00:00:00',
      // endTime: '23:59:00',
      // customTimeSlot: 1,
      // weekdaysCustomGroup: 1,
    }
  }));
  _getSimultaneousPrograms = (): Promise<SimultaneousProgramsDTO> => new Promise(res => res({
    mainProgram: {
      startTime: '20:29:00',
      endTime: '21:56:00',
    },
    secondaryTvNetworks: [
      {
        tvNetworkId: '2',
        exhibitionName: 'RECORD',
        color: '#ff0000',
        programs: [
          {
            name: 'RETROSPECTIVA FAMOSO R',
            startTime: '20:29:00',
            endTime: '21:56:00',
            exhibitionPercentage: 100,
            isChild: false
          }
        ]
      },
      {
        tvNetworkId: '3',
        exhibitionName: 'SBT',
        color: '#00ff00',
        programs: [
          {
            name: 'RODA A RODA JEQUITI',
            startTime: '20:29:00',
            endTime: '20:54:00',
            exhibitionPercentage: 41,
            isChild: false
          },
          {
            name: 'NOVELA NOITE 1',
            startTime: '-',
            endTime: '-',
            exhibitionPercentage: 0,
            isChild: false
          },
          {
            name: 'AS AVENTURAS DE POLIANA',
            startTime: '20:54',
            endTime: '21:51',
            exhibitionPercentage: 59,
            isChild: true
          }
        ]
      },
      {
        tvNetworkId: '4',
        exhibitionName: 'BAND',
        color: '#0000ff',
        programs: [
          {
            name: 'BAND SPORT',
            startTime: '20:29:00',
            endTime: '21:56:00',
            exhibitionPercentage: 100,
            isChild: false
          }
        ]
      },
    ]
  }));
  _searchPrograms = () => new Promise(res => res({
    programs: [
      { label: "Jornal Nacional", value:"JN"},
      { label: "Jornal da Globo", value:"JG"},
      { label: "Jornal Hoje", value:"JH"},
    ]
  }));

  _getProgramLastExhibitionDate = async (params: ProgramLastExhibitionDateParamsDTO): Promise<ProgramLastExhibitionDateDTO> => {
    console.log({ params });
    return new Promise((res) => res({
      lastExhibitionDate: '2021-01-01',
    }));
  };

  _getDailyGrid = (_:DailyGridSearchParamsDTO): Promise<DailyGridDTO> => new Promise(res => res({
    mainTvNetwork: {
      name: 'GLOBO',
      color: '#0088cc',
      programs: [
        {id:'PG0', name:'RELIGIOSO MAT', startTime:'06:00:00', endTime:'00:00:00', totalShare:10, audienceData: { audience: { value: 1 }, share: { value: 5 }}, showChildren:false, },
        {id:'PG1', name:'FILHO1', startTime:'23:59:59', endTime:'00:00:00', totalShare:99, childOf:'PG0', audienceData: { audience: { value: 91.5 }, share: { value: 92.999 }},},
        {id:'PG2', name:'FILHO2', startTime:'23:59:59', endTime:'00:00:00', totalShare:99, childOf:'PG0', audienceData: { audience: { value: 93 }, share: { value: 94 }},},
        {id:'PG3', name:'FILHO3', startTime:'23:59:59', endTime:'00:00:00', totalShare:99, childOf:'PG0', audienceData: { audience: { value: 95 }, share: { value: 96 }},},
        {id:'PG4', name:'ANTENA PAULISTA', startTime:'07:00:00', endTime:'00:00:00', totalShare:11, audienceData: { audience: { value: 2.444 }, share: { value: 6 }},},
        {id:'PG5', name:'P EMPRESAS G NEGOCIOS', startTime:'08:00:00', endTime:'00:00:00', totalShare:12, audienceData: { audience: { value: 3 }, share: { value: 7 }},},
        {id:'PG6', name:'GLOBO RURAL DM', startTime:'09:00:00', endTime:'00:00:00', totalShare:13, audienceData: { audience: { value: 4 }, share: { value: 8 }},},
        {id:'PG7', name:'RELIGIOSO MAT', startTime:'06:00:00', endTime:'00:00:00', totalShare:10, audienceData: { audience: { value: 1 }, share: { value: 5 }},},
        {id:'PG8', name:'ANTENA PAULISTA', startTime:'07:00:00', endTime:'00:00:00', totalShare:11, audienceData: { audience: { value: 2 }, share: { value: 6 }},},
        {id:'PG9', name:'P EMPRESAS G NEGOCIOS', startTime:'08:00:00', endTime:'00:00:00', totalShare:12, audienceData: { audience: { value: 3 }, share: { value: 7 }},},
        {id:'PG10', name:'GLOBO RURAL DM', startTime:'09:00:00', endTime:'00:00:00', totalShare:13, audienceData: { audience: { value: 4 }, share: { value: 8 }},},
        {id:'PG11', name:'RELIGIOSO MAT', startTime:'06:00:00', endTime:'00:00:00', totalShare:10, audienceData: { audience: { value: 1 }, share: { value: 5 }},},
        {id:'PG12', name:'ANTENA PAULISTA', startTime:'07:00:00', endTime:'00:00:00', totalShare:11, audienceData: { audience: { value: 2 }, share: { value: 6 }},},
        {id:'PG13', name:'P EMPRESAS G NEGOCIOS', startTime:'08:00:00', endTime:'00:00:00', totalShare:12, audienceData: { audience: { value: 3 }, share: { value: 7 }},},
        {id:'PG14', name:'GLOBO RURAL DM', startTime:'09:00:00', endTime:'00:00:00', totalShare:13, audienceData: { audience: { value: 4 }, share: { value: 8 }}, showChildren:false,},
        {id:'PG15', name:'FILHO 1', startTime:'23:59:59', endTime:'00:00:00', totalShare:99, childOf:'PG14', audienceData: { audience: { value: 97 }, share: { value: 98 }},},
      ],
    },
    secondaryTvNetworks: [
      {
        name: 'REC',
        color: '#aa0000',
        audienceSeries: [
          { audience: { value: 7, }, share: { value: 11.2, }, programId:'PG0', },
          { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
          { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
          { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
          { audience: { value: 8, }, share: { value: 12, }, programId:'PG4', },
          { audience: { value: 9, }, share: { value: 13, }, programId:'PG5', },
          { audience: { value: 10, }, share: { value: 14, }, programId:'PG6', },
          { audience: { value: 7, }, share: { value: 11, }, programId:'PG7', },
          { audience: { value: 8, }, share: { value: 12, }, programId:'PG8', },
          { audience: { value: 9, }, share: { value: 13, }, programId:'PG9', },
          { audience: { value: 10, }, share: { value: 14, }, programId:'PG10', },
          { audience: { value: 7, }, share: { value: 11, }, programId:'PG11', },
          { audience: { value: 8, }, share: { value: 12, }, programId:'PG12', },
          { audience: { value: 9, }, share: { value: 13, }, programId:'PG13', },
          { audience: { value: 10, }, share: { value: 14, }, programId:'PG14', },
          { audience: { value: 97.222, }, share: { value: 98, }, programId:'PG15', },
        ],
      },
      {
        name: 'SBT',
        color: '#00aa00',
        audienceSeries: [
          { audience: { value: 15, }, share: { value: 19, }, programId:'PG0', },
          { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
          { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
          { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
          { audience: { value: 16, }, share: { value: 20, }, programId:'PG4', },
          { audience: { value: 17, }, share: { value: 21, }, programId:'PG5', },
          { audience: { value: 18, }, share: { value: 22, }, programId:'PG6', },
          { audience: { value: 15, }, share: { value: 19, }, programId:'PG7', },
          { audience: { value: 16, }, share: { value: 20, }, programId:'PG8', },
          { audience: { value: 17, }, share: { value: 21, }, programId:'PG9', },
          { audience: { value: 18, }, share: { value: 22, }, programId:'PG10', },
          { audience: { value: 15, }, share: { value: 19, }, programId:'PG11', },
          { audience: { value: 16, }, share: { value: 20, }, programId:'PG12', },
          { audience: { value: 17, }, share: { value: 21, }, programId:'PG13', },
          { audience: { value: 18, }, share: { value: 22, }, programId:'PG14', },
          { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
        ],
      },
      {
        name: 'BAND',
        color: '#0000aa',
        audienceSeries: [
          { audience: { value: 23, }, share: { value: 27, }, programId:'PG0', },
          { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
          { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
          { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
          { audience: { value: 24, }, share: { value: 28, }, programId:'PG4', },
          { audience: { value: 25, }, share: { value: 29, }, programId:'PG5', },
          { audience: { value: 26, }, share: { value: 30, }, programId:'PG6', },
          { audience: { value: 23, }, share: { value: 27, }, programId:'PG7', },
          { audience: { value: 24, }, share: { value: 28, }, programId:'PG8', },
          { audience: { value: 25, }, share: { value: 29, }, programId:'PG8', },
          { audience: { value: 26, }, share: { value: 30, }, programId:'PG10', },
          { audience: { value: 23, }, share: { value: 27, }, programId:'PG11', },
          { audience: { value: 24, }, share: { value: 28, }, programId:'PG12', },
          { audience: { value: 25, }, share: { value: 29, }, programId:'PG13', },
          { audience: { value: 26, }, share: { value: 30, }, programId:'PG14', },
          { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
        ],
      },
      {
        name: 'REC2',
        color: '#aa0000',
        audienceSeries: [
          { audience: { value: 7, }, share: { value: 11, }, programId:'PG0', },
          { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
          { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
          { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
          { audience: { value: 8, }, share: { value: 12, }, programId:'PG4', },
          { audience: { value: 9, }, share: { value: 13, }, programId:'PG5', },
          { audience: { value: 10, }, share: { value: 14, }, programId:'PG6', },
          { audience: { value: 7, }, share: { value: 11, }, programId:'PG7', },
          { audience: { value: 8, }, share: { value: 12, }, programId:'PG8', },
          { audience: { value: 9, }, share: { value: 13, }, programId:'PG9', },
          { audience: { value: 10, }, share: { value: 14, }, programId:'PG10', },
          { audience: { value: 7, }, share: { value: 11, }, programId:'PG11', },
          { audience: { value: 8, }, share: { value: 12, }, programId:'PG12', },
          { audience: { value: 9, }, share: { value: 13, }, programId:'PG13', },
          { audience: { value: 10, }, share: { value: 14, }, programId:'PG14', },
          { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
        ],
      },
      {
        name: 'SBT2',
        color: '#00aa00',
        audienceSeries: [
          { audience: { value: 15, }, share: { value: 19, }, programId:'PG0', },
          { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
          { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
          { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
          { audience: { value: 16, }, share: { value: 20, }, programId:'PG4', },
          { audience: { value: 17, }, share: { value: 21, }, programId:'PG5', },
          { audience: { value: 18, }, share: { value: 22, }, programId:'PG6', },
          { audience: { value: 15, }, share: { value: 19, }, programId:'PG7', },
          { audience: { value: 16, }, share: { value: 20, }, programId:'PG8', },
          { audience: { value: 17, }, share: { value: 21, }, programId:'PG9', },
          { audience: { value: 18, }, share: { value: 22, }, programId:'PG10', },
          { audience: { value: 15, }, share: { value: 19, }, programId:'PG11', },
          { audience: { value: 16, }, share: { value: 20, }, programId:'PG12', },
          { audience: { value: 17, }, share: { value: 21, }, programId:'PG13', },
          { audience: { value: 18, }, share: { value: 22, }, programId:'PG14', },
          { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
        ],
      },
      {
        name: 'BAND2',
        color: '#0000aa',
        audienceSeries: [
          { audience: { value: 23, }, share: { value: 27, }, programId:'PG0', },
          { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
          { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
          { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
          { audience: { value: 24, }, share: { value: 28, }, programId:'PG4', },
          { audience: { value: 25, }, share: { value: 29, }, programId:'PG5', },
          { audience: { value: 26, }, share: { value: 30, }, programId:'PG6', },
          { audience: { value: 23, }, share: { value: 27, }, programId:'PG7', },
          { audience: { value: 24, }, share: { value: 28, }, programId:'PG8', },
          { audience: { value: 25, }, share: { value: 29, }, programId:'PG8', },
          { audience: { value: 26, }, share: { value: 30, }, programId:'PG10', },
          { audience: { value: 23, }, share: { value: 27, }, programId:'PG11', },
          { audience: { value: 24, }, share: { value: 28, }, programId:'PG12', },
          { audience: { value: 25, }, share: { value: 29, }, programId:'PG13', },
          { audience: { value: 26, }, share: { value: 30, }, programId:'PG14', },
          { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
        ],
      },
      {
        name: 'REC3',
        color: '#aa0000',
        audienceSeries: [
          { audience: { value: 7, }, share: { value: 11, }, programId:'PG0', },
          { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
          { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
          { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
          { audience: { value: 8, }, share: { value: 12, }, programId:'PG4', },
          { audience: { value: 9, }, share: { value: 13, }, programId:'PG5', },
          { audience: { value: 10, }, share: { value: 14, }, programId:'PG6', },
          { audience: { value: 7, }, share: { value: 11, }, programId:'PG7', },
          { audience: { value: 8, }, share: { value: 12, }, programId:'PG8', },
          { audience: { value: 9, }, share: { value: 13, }, programId:'PG9', },
          { audience: { value: 10, }, share: { value: 14, }, programId:'PG10', },
          { audience: { value: 7, }, share: { value: 11, }, programId:'PG11', },
          { audience: { value: 8, }, share: { value: 12, }, programId:'PG12', },
          { audience: { value: 9, }, share: { value: 13, }, programId:'PG13', },
          { audience: { value: 10, }, share: { value: 14, }, programId:'PG14', },
          { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
        ],
      },
      {
        name: 'SBT3',
        color: '#00aa00',
        audienceSeries: [
          { audience: { value: 15, }, share: { value: 19, }, programId:'PG0', },
          { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
          { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
          { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
          { audience: { value: 16, }, share: { value: 20, }, programId:'PG4', },
          { audience: { value: 17, }, share: { value: 21, }, programId:'PG5', },
          { audience: { value: 18, }, share: { value: 22, }, programId:'PG6', },
          { audience: { value: 15, }, share: { value: 19, }, programId:'PG7', },
          { audience: { value: 16, }, share: { value: 20, }, programId:'PG8', },
          { audience: { value: 17, }, share: { value: 21, }, programId:'PG9', },
          { audience: { value: 18, }, share: { value: 22, }, programId:'PG10', },
          { audience: { value: 15, }, share: { value: 19, }, programId:'PG11', },
          { audience: { value: 16, }, share: { value: 20, }, programId:'PG12', },
          { audience: { value: 17, }, share: { value: 21, }, programId:'PG13', },
          { audience: { value: 18, }, share: { value: 22, }, programId:'PG14', },
          { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
        ],
      },
      {
        name: 'BAND3',
        color: '#0000aa',
        audienceSeries: [
          { audience: { value: 23, }, share: { value: 27, }, programId:'PG0', },
          { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
          { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
          { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
          { audience: { value: 24, }, share: { value: 28, }, programId:'PG4', },
          { audience: { value: 25, }, share: { value: 29, }, programId:'PG5', },
          { audience: { value: 26, }, share: { value: 30, }, programId:'PG6', },
          { audience: { value: 23, }, share: { value: 27, }, programId:'PG7', },
          { audience: { value: 24, }, share: { value: 28, }, programId:'PG8', },
          { audience: { value: 25, }, share: { value: 29, }, programId:'PG8', },
          { audience: { value: 26, }, share: { value: 30, }, programId:'PG10', },
          { audience: { value: 23, }, share: { value: 27, }, programId:'PG11', },
          { audience: { value: 24, }, share: { value: 28, }, programId:'PG12', },
          { audience: { value: 25, }, share: { value: 29, }, programId:'PG13', },
          { audience: { value: 26, }, share: { value: 30, }, programId:'PG14', },
          { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
        ],
      },
    ]
  }));
  _getDailyGridFilters = () => new Promise(res => res({
    markets: [
      { label: 'GSP - GRANDE SÃO PAULO', value:'GSP' },
      { label: 'PNC - PNT COMPLETO', value:'PNC' },
    ],
    targets: [
      { label: 'DOMICILIAR', value:'domiciliar' },
      { label: 'HOMENS', value:'h' },
      { label: 'MULHERES', value:'m' },
    ],
    tvNetworks: [
      { label: 'TV GLOBO', value:'GLOBO' },
      { label: 'REDE RECORD', value:'REC' },
      { label: 'SBT', value:'SBT' },
      { label: 'BANDEIRANTES', value:'BAND' },
    ],
    connectedTvsSumTypes: [
      { label: 'TL', value:'tl' },
      { label: 'TLE', value:'tle' },
    ],
    decimalPlaces: [
      { label: 'nenhuma', value: 0 },
      { label: 'uma', value: 1 },
      { label: 'duas', value: 2 },
    ],
    defaultValues: {
      market: 'GSP',
      target: 'domiciliar',
      mainTvNetwork: 'GLOBO',
      secondaryTvNetworks: ['REC', 'BAND'],
      connectedTvsSumType: 'tle',
      startDate: '2021-01-31',
      startTime: '00:00:00',
      endTime: '23:59:59',
      decimalPlaces: 0,
    }
  }));

  randomIntFromInterval(min: number, max:number): number { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  generateRandomSeries(min: number, max: number, includeViewersProjection?: boolean) {
    const series = [];
    let currentTime = 1417943497000;
    for(let i=0; i<500; i++) {
      const serieObj: any = {
        x: currentTime,
        audience: this.randomIntFromInterval(min, max),
        share: this.randomIntFromInterval(min, max),
        program: 'Programa ' + i,
      };
      if (includeViewersProjection) {
        serieObj.viewersProjection = 11111;
      }
      series.push(serieObj);
      currentTime+=60000;
    }
    return series;
  }

  _getMinuteByMinute = () => new Promise(res => {
    const series = [];
    series.push({color:'#0088cc', name: 'Globo', visible: true, data:this.generateRandomSeries(11,13, true)});
    series.push({color:'#00aa22', name: 'Record', visible: true, data:this.generateRandomSeries(7,8)});
    series.push({color:'#aa0022', name: 'SBT', visible: true, data:this.generateRandomSeries(9,12)});
    res({
      series,
      programs: [
        {
          startTime: 1417943497000,
          endTime: 1417950547000,
          name: 'jornal nacional',
        },
        {
          startTime: 1417950547000,
          endTime: 1417958547000,
          name: 'novela 3',
        },
        {
          startTime: 1417958547000,
          endTime: 1417973497000,
          name: 'tela quente',
        },
      ]
    });
  });
  _getMinuteByMinuteFilters = () => new Promise(res => res({
    markets: [
      { label: 'GSP - GRANDE SÃO PAULO', value:'GSP' },
      { label: 'PNC - PNT COMPLETO', value:'PNC' },
    ],
    targets: [
      { label: 'DOMICILIAR', value:'domiciliar' },
      { label: 'HOMENS', value:'h' },
      { label: 'MULHERES', value:'m' },
    ],
    tvNetworks: [
      { label: 'TV GLOBO', value:'GLOBO' },
      { label: 'REDE RECORD', value:'REC' },
      { label: 'SBT', value:'SBT' },
      { label: 'BANDEIRANTES', value:'BAND' },
    ],
    connectedTvsSumTypes: [
      { label: 'TL', value:'tl' },
      { label: 'TLE', value:'tle' },
    ],
    defaultValues: {
      market: 'GSP',
      target: 'domiciliar',
      mainTvNetwork: 'GLOBO',
      secondaryTvNetworks: ['REC', 'BAND'],
      connectedTvsSumType: 'tle',
      startDate: '2021-01-31',
      share: false,
    }
  }));
  _getMinuteByMinuteTvNetworkGrids = () => new Promise(res => res({
    grids: [
      {
        mainTvNetwork: {
          name: 'GLOBO',
          color: '#0088cc',
          programs: [
            {id:'PG0', name:'RELIGIOSO MAT', startTime:'06:00:00', endTime:'00:00:00', tle:10, audienceData: { audience: { value: 1, status: 'better' }, share: { value: 5, status: 'better' }}, showChildren:false, },
            {id:'PG1', name:'FILHO1', startTime:'23:59:59', endTime:'00:00:00', tle:99, childOf:'PG0', audienceData: { audience: { value: 91, status: 'better' }, share: { value: 92, status: 'better' }},},
            {id:'PG2', name:'FILHO2', startTime:'23:59:59', endTime:'00:00:00', tle:99, childOf:'PG0', audienceData: { audience: { value: 93, status: 'better' }, share: { value: 94, status: 'better' }},},
            {id:'PG3', name:'FILHO3', startTime:'23:59:59', endTime:'00:00:00', tle:99, childOf:'PG0', audienceData: { audience: { value: 95, status: 'better' }, share: { value: 96, status: 'better' }},},
            {id:'PG4', name:'ANTENA PAULISTA', startTime:'07:00:00', endTime:'00:00:00', tle:11, audienceData: { audience: { value: 2, status: 'worse' }, share: { value: 6, status: 'worse' }},},
            {id:'PG5', name:'P EMPRESAS G NEGOCIOS', startTime:'08:00:00', endTime:'00:00:00', tle:12, audienceData: { audience: { value: 3, status: 'equal' }, share: { value: 7, status: 'equal' }},},
            {id:'PG6', name:'GLOBO RURAL DM', startTime:'09:00:00', endTime:'00:00:00', tle:13, audienceData: { audience: { value: 4, status: 'better' }, share: { value: 8, status: 'better' }},},
            {id:'PG7', name:'RELIGIOSO MAT', startTime:'06:00:00', endTime:'00:00:00', tle:10, audienceData: { audience: { value: 1, status: 'better' }, share: { value: 5, status: 'better' }},},
            {id:'PG8', name:'ANTENA PAULISTA', startTime:'07:00:00', endTime:'00:00:00', tle:11, audienceData: { audience: { value: 2, status: 'better' }, share: { value: 6, status: 'better' }},},
            {id:'PG9', name:'P EMPRESAS G NEGOCIOS', startTime:'08:00:00', endTime:'00:00:00', tle:12, audienceData: { audience: { value: 3, status: 'better' }, share: { value: 7, status: 'better' }},},
            {id:'PG10', name:'GLOBO RURAL DM', startTime:'09:00:00', endTime:'00:00:00', tle:13, audienceData: { audience: { value: 4, status: 'better' }, share: { value: 8, status: 'better' }},},
            {id:'PG11', name:'RELIGIOSO MAT', startTime:'06:00:00', endTime:'00:00:00', tle:10, audienceData: { audience: { value: 1, status: 'better' }, share: { value: 5, status: 'better' }},},
            {id:'PG12', name:'ANTENA PAULISTA', startTime:'07:00:00', endTime:'00:00:00', tle:11, audienceData: { audience: { value: 2, status: 'better' }, share: { value: 6, status: 'better' }},},
            {id:'PG13', name:'P EMPRESAS G NEGOCIOS', startTime:'08:00:00', endTime:'00:00:00', tle:12, audienceData: { audience: { value: 3, status: 'better' }, share: { value: 7, status: 'better' }},},
            {id:'PG14', name:'GLOBO RURAL DM', startTime:'09:00:00', endTime:'00:00:00', tle:13, audienceData: { audience: { value: 4, status: 'better' }, share: { value: 8, status: 'better' }}, showChildren:false,},
            {id:'PG15', name:'FILHO 1', startTime:'23:59:59', endTime:'00:00:00', tle:99, childOf:'PG14', audienceData: { audience: { value: 97, status: 'better' }, share: { value: 98, status: 'better' }},},
          ],
        },
        secondaryTvNetworks: [
          {
            name: 'REC',
            color: '#aa0000',
            audienceSeries: [
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 8, status:'better' }, share: { value: 12, status:'better' }, programId:'PG4', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG5', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG6', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG7', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG8', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG9', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG10', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG11', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG12', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG13', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'SBT',
            color: '#00aa00',
            audienceSeries: [
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG4', },
              { audience: { value: 17, status:'equal' }, share: { value: 21, status:'equal' }, programId:'PG5', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG6', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG7', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG8', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG9', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG10', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG11', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG12', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG13', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'BAND',
            color: '#0000aa',
            audienceSeries: [
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG4', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG5', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG6', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG7', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG8', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG8', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG10', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG11', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG12', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG13', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'REC2',
            color: '#aa0000',
            audienceSeries: [
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG4', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG5', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG6', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG7', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG8', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG9', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG10', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG11', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG12', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG13', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'SBT2',
            color: '#00aa00',
            audienceSeries: [
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG4', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG5', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG6', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG7', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG8', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG9', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG10', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG11', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG12', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG13', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'BAND2',
            color: '#0000aa',
            audienceSeries: [
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG4', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG5', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG6', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG7', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG8', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG8', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG10', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG11', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG12', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG13', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'REC3',
            color: '#aa0000',
            audienceSeries: [
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG4', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG5', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG6', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG7', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG8', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG9', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG10', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG11', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG12', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG13', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'SBT3',
            color: '#00aa00',
            audienceSeries: [
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG4', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG5', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG6', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG7', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG8', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG9', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG10', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG11', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG12', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG13', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'BAND3',
            color: '#0000aa',
            audienceSeries: [
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG4', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG5', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG6', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG7', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG8', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG8', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG10', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG11', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG12', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG13', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
        ]
      },
      {
        mainTvNetwork: {
          name: 'GLOBO',
          color: '#0088cc',
          programs: [
            {id:'PG0', name:'RELIGIOSO MAT', startTime:'06:00:00', endTime:'00:00:00', tle:10, audienceData: { audience: { value: 1, status: 'better' }, share: { value: 5, status: 'better' }}, showChildren:false, },
            {id:'PG1', name:'FILHO1', startTime:'23:59:59', endTime:'00:00:00', tle:99, childOf:'PG0', audienceData: { audience: { value: 91, status: 'better' }, share: { value: 92, status: 'better' }},},
            {id:'PG2', name:'FILHO2', startTime:'23:59:59', endTime:'00:00:00', tle:99, childOf:'PG0', audienceData: { audience: { value: 93, status: 'better' }, share: { value: 94, status: 'better' }},},
            {id:'PG3', name:'FILHO3', startTime:'23:59:59', endTime:'00:00:00', tle:99, childOf:'PG0', audienceData: { audience: { value: 95, status: 'better' }, share: { value: 96, status: 'better' }},},
            {id:'PG4', name:'ANTENA PAULISTA', startTime:'07:00:00', endTime:'00:00:00', tle:11, audienceData: { audience: { value: 2, status: 'better' }, share: { value: 6, status: 'better' }},},
            {id:'PG5', name:'P EMPRESAS G NEGOCIOS', startTime:'08:00:00', endTime:'00:00:00', tle:12, audienceData: { audience: { value: 3, status: 'better' }, share: { value: 7, status: 'better' }},},
            {id:'PG6', name:'GLOBO RURAL DM', startTime:'09:00:00', endTime:'00:00:00', tle:13, audienceData: { audience: { value: 4, status: 'better' }, share: { value: 8, status: 'better' }},},
            {id:'PG7', name:'RELIGIOSO MAT', startTime:'06:00:00', endTime:'00:00:00', tle:10, audienceData: { audience: { value: 1, status: 'better' }, share: { value: 5, status: 'better' }},},
            {id:'PG8', name:'ANTENA PAULISTA', startTime:'07:00:00', endTime:'00:00:00', tle:11, audienceData: { audience: { value: 2, status: 'better' }, share: { value: 6, status: 'better' }},},
            {id:'PG9', name:'P EMPRESAS G NEGOCIOS', startTime:'08:00:00', endTime:'00:00:00', tle:12, audienceData: { audience: { value: 3, status: 'better' }, share: { value: 7, status: 'better' }},},
            {id:'PG10', name:'GLOBO RURAL DM', startTime:'09:00:00', endTime:'00:00:00', tle:13, audienceData: { audience: { value: 4, status: 'better' }, share: { value: 8, status: 'better' }},},
            {id:'PG11', name:'RELIGIOSO MAT', startTime:'06:00:00', endTime:'00:00:00', tle:10, audienceData: { audience: { value: 1, status: 'better' }, share: { value: 5, status: 'better' }},},
            {id:'PG12', name:'ANTENA PAULISTA', startTime:'07:00:00', endTime:'00:00:00', tle:11, audienceData: { audience: { value: 2, status: 'better' }, share: { value: 6, status: 'better' }},},
            {id:'PG13', name:'P EMPRESAS G NEGOCIOS', startTime:'08:00:00', endTime:'00:00:00', tle:12, audienceData: { audience: { value: 3, status: 'better' }, share: { value: 7, status: 'better' }},},
            {id:'PG14', name:'GLOBO RURAL DM', startTime:'09:00:00', endTime:'00:00:00', tle:13, audienceData: { audience: { value: 4, status: 'better' }, share: { value: 8, status: 'better' }}, showChildren:false,},
            {id:'PG15', name:'FILHO 1', startTime:'23:59:59', endTime:'00:00:00', tle:99, childOf:'PG14', audienceData: { audience: { value: 97, status: 'better' }, share: { value: 98, status: 'better' }},},
          ],
        },
        secondaryTvNetworks: [
          {
            name: 'REC',
            color: '#aa0000',
            audienceSeries: [
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG4', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG5', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG6', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG7', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG8', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG9', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG10', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG11', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG12', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG13', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'SBT',
            color: '#00aa00',
            audienceSeries: [
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG4', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG5', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG6', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG7', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG8', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG9', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG10', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG11', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG12', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG13', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'BAND',
            color: '#0000aa',
            audienceSeries: [
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG4', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG5', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG6', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG7', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG8', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG8', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG10', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG11', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG12', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG13', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'REC2',
            color: '#aa0000',
            audienceSeries: [
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG4', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG5', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG6', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG7', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG8', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG9', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG10', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG11', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG12', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG13', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'SBT2',
            color: '#00aa00',
            audienceSeries: [
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG4', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG5', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG6', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG7', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG8', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG9', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG10', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG11', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG12', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG13', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'BAND2',
            color: '#0000aa',
            audienceSeries: [
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG4', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG5', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG6', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG7', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG8', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG8', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG10', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG11', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG12', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG13', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'REC3',
            color: '#aa0000',
            audienceSeries: [
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG4', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG5', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG6', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG7', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG8', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG9', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG10', },
              { audience: { value: 7, }, share: { value: 11, }, programId:'PG11', },
              { audience: { value: 8, }, share: { value: 12, }, programId:'PG12', },
              { audience: { value: 9, }, share: { value: 13, }, programId:'PG13', },
              { audience: { value: 10, }, share: { value: 14, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'SBT3',
            color: '#00aa00',
            audienceSeries: [
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG4', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG5', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG6', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG7', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG8', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG9', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG10', },
              { audience: { value: 15, }, share: { value: 19, }, programId:'PG11', },
              { audience: { value: 16, }, share: { value: 20, }, programId:'PG12', },
              { audience: { value: 17, }, share: { value: 21, }, programId:'PG13', },
              { audience: { value: 18, }, share: { value: 22, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
          {
            name: 'BAND3',
            color: '#0000aa',
            audienceSeries: [
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG0', },
              { audience: { value: 91, }, share: { value: 92, }, programId:'PG1', },
              { audience: { value: 93, }, share: { value: 94, }, programId:'PG2', },
              { audience: { value: 95, }, share: { value: 96, }, programId:'PG3', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG4', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG5', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG6', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG7', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG8', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG8', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG10', },
              { audience: { value: 23, }, share: { value: 27, }, programId:'PG11', },
              { audience: { value: 24, }, share: { value: 28, }, programId:'PG12', },
              { audience: { value: 25, }, share: { value: 29, }, programId:'PG13', },
              { audience: { value: 26, }, share: { value: 30, }, programId:'PG14', },
              { audience: { value: 97, }, share: { value: 98, }, programId:'PG15', },
            ],
          },
        ]
      },
    ]
  }));

  _getConsolidatedByMarketBySlot = () => new Promise(res => res({
    marketGroups: [
      {
        name: 'grupo 1',
        tvNetworks: [
          { id:'GSP', updatedAt: '01/01/1999' },
        ]
      },
      {
        name: 'grupo 2',
        tvNetworks: [
          { id:'SP1', updatedAt: '02/01/1999' },
          { id:'GCA', updatedAt: '03/01/1999' },
          { id:'RJ', updatedAt: '04/01/1999' },
          { id:'BHZ', updatedAt: '05/01/1999' },
          { id:'GVI', updatedAt: '06/01/1999' },
        ]
      },
      {
        name: 'grupo 3',
        tvNetworks: [
          { id:'POA', updatedAt: '07/01/1999' },
          { id:'FLP', updatedAt: '08/01/1999' },
          { id:'CTA', updatedAt: '09/01/1999' },
        ]
      },
      {
        name: 'grupo 4',
        tvNetworks: [
          { id:'DFE', updatedAt: '10/01/1999' },
          { id:'GOI', updatedAt: '11/01/1999' },
        ]
      },
      {
        name: 'grupo 5',
        tvNetworks: [
          { id:'REC', updatedAt: '12/01/1999' },
          { id:'SAL', updatedAt: '13/01/1999' },
          { id:'FOT', updatedAt: '14/01/1999' },
        ]
      },
    ],
    series: [
      {
        data: [{name:'GSP',y:12.999},{name:'SP1', y:10.999},{name:'GCA', y:15.999},{name:'RJ', y:10.999},{name:'BHZ', y:11.999},{name:'GVI', y:13.999},{name:'POA', y:12.999},{name:'FLP', y:12.999},{name:'CTA', y:9.999},{name:'DFE', y:8.999},{name:'GOI', y:13.999},{name:'REC', y:12.999},{name:'SAL', y:10.999},{name:'FOT', y:12.999}],
        color: '#0088cc',
        name: 'Globo',
      },
      {
        data: [{name:'GSP',y:11.2},{name:'SP1', y:7},{name:'GCA', y:5.33},{name:'RJ', y:10},{name:'BHZ', y:11},{name:'GVI', y:5.22},{name:'POA', y:8.54},{name:'FLP', y:7.3333},{name:'CTA', y:8},{name:'DFE', y:5.333},{name:'GOI', y:8.12},{name:'REC', y:7.67},{name:'SAL', y:7.2},{name:'FOT', y:5.44}],
        color: '#00aa22',
        name: 'Record'
      },
      {
        data: [{name:'GSP'},{name:'SP1', y:6.1},{name:'GCA', y:5.1},{name:'RJ', y:4.1},{name:'BHZ', y:6.1},{name:'GVI', y:6.2},{name:'POA', y:5.4},{name:'FLP', y:3},{name:'CTA', y:2.5},{name:'DFE', y:2.1},{name:'GOI', y:1.9},{name:'REC', y:5.3},{name:'SAL', y:4.2},{name:'FOT', y:5.8}],
        color: '#aa0022',
        name: 'SBT'
      },
    ],
  }));
  _getConsolidatedByMarketBySlotFilters = () => new Promise(res => {
    return res({
    targets: [
      { label: 'DOMICILIAR', value:'domiciliar' },
      { label: 'HOMENS', value:'h' },
      { label: 'MULHERES', value:'m' },
    ],
    tvNetworks: [
      { label: 'TV GLOBO', value:'GLOBO' },
      { label: 'REDE RECORD', value:'REC' },
      { label: 'SBT', value:'SBT' },
      { label: 'BANDEIRANTES', value:'BAND' },
    ],
    connectedTvsSumTypes: [
      { label: 'TL', value:'tl' },
      { label: 'TLE', value:'tle' },
    ],
    customTimeSlots: [
      { label: '6H as 5H59', value: '6H as 5H59'},
      { label: '6H as 12H', value: '6H as 12H' },
      { label: '12H as 5H59', value: '12H as 5H59' },
    ],
    weekdaysCustomGroups: [
      { label: 'TODOS OS DIAS', value: 'TODOS OS DIAS' },
      { label: 'SEG A SAB', value: 'SEG A SAB' },
    ],
    defaultValues: {
      target: 'domiciliar',
      secondaryTvNetworks: ['REC', 'BAND'],
      weekdaysCustomGroup: 'TODOS OS DIAS',
      customTimeSlot: '6H as 5H59',
      connectedTvsSumType: 'tle',
      startDate: '2021-01-31',
      endDate: '2021-01-31',
      share: false,
    }
  })});

  _getConsolidatedByMarketByProgram = () => new Promise(res => res({
    marketGroups: [
      {
        name: 'grupo 1',
        markets: [
          { id:'GSP', updatedAt: '01/01/1999' },
        ]
      },
      {
        name: 'grupo 2',
        markets: [
          { id:'SP1', updatedAt: '02/01/1999' },
          { id:'GCA', updatedAt: '03/01/1999' },
          { id:'RJ', updatedAt: '04/01/1999' },
          { id:'BHZ', updatedAt: '05/01/1999' },
          { id:'GVI', updatedAt: '06/01/1999' },
        ]
      },
      {
        name: 'grupo 3',
        markets: [
          { id:'POA', updatedAt: '07/01/1999' },
          { id:'FLP', updatedAt: '08/01/1999' },
          { id:'CTA', updatedAt: '09/01/1999' },
        ]
      },
      {
        name: 'grupo 4',
        markets: [
          { id:'DFE', updatedAt: '10/01/1999' },
          { id:'GOI', updatedAt: '11/01/1999' },
        ]
      },
      {
        name: 'grupo 5',
        markets: [
          { id:'REC', updatedAt: '12/01/1999' },
          { id:'SAL', updatedAt: '13/01/1999' },
          { id:'FOT', updatedAt: '14/01/1999' },
        ]
      },
    ],
    series: [
      {
        data: [{name:'GSP',y:12.999},{name:'SP1', y:10.999},{name:'GCA', y:15.999},{name:'RJ', y:10.999},{name:'BHZ', y:11.999},{name:'GVI', y:13.999},{name:'POA', y:12.999},{name:'FLP', y:12.999},{name:'CTA', y:9.999},{name:'DFE', y:8.999},{name:'GOI', y:13.999},{name:'REC', y:12.999},{name:'SAL', y:10.999},{name:'FOT', y:12.999}],
        color: '#0088cc',
        name: 'Globo',
      },
      {
        data: [{name:'GSP',y:11.2},{name:'SP1', y:7},{name:'GCA', y:5.33},{name:'RJ', y:10},{name:'BHZ', y:11},{name:'GVI', y:5.22},{name:'POA', y:8.54},{name:'FLP', y:7.3333},{name:'CTA', y:8},{name:'DFE', y:5.333},{name:'GOI', y:8.12},{name:'REC', y:7.67},{name:'SAL', y:7.2},{name:'FOT', y:5.44}],
        color: '#00aa22',
        name: 'Record'
      },
      {
        data: [{name:'GSP'},{name:'SP1', y:6.1},{name:'GCA', y:5.1},{name:'RJ', y:4.1},{name:'BHZ', y:6.1},{name:'GVI', y:6.2},{name:'POA', y:5.4},{name:'FLP', y:3},{name:'CTA', y:2.5},{name:'DFE', y:2.1},{name:'GOI', y:1.9},{name:'REC', y:5.3},{name:'SAL', y:4.2},{name:'FOT', y:5.8}],
        color: '#aa0022',
        name: 'SBT'
      },
    ],
  }));
  _getConsolidatedByMarketByProgramFilters = () => new Promise(res => res({
    targets: [
      { label: 'DOMICILIAR', value:'domiciliar' },
      { label: 'HOMENS', value:'h' },
      { label: 'MULHERES', value:'m' },
    ],
    tvNetworks: [
      { label: 'TV GLOBO', value:'GLOBO' },
      { label: 'REDE RECORD', value:'REC' },
      { label: 'SBT', value:'SBT' },
      { label: 'BANDEIRANTES', value:'BAND' },
    ],
    connectedTvsSumTypes: [
      { label: 'TL', value:'tl' },
      { label: 'TLE', value:'tle' },
    ],
    customTimeSlots: [
      { label: '6H as 5H59', value: '6H as 5H59'},
      { label: '6H as 12H', value: '6H as 12H' },
      { label: '12H as 5H59', value: '12H as 5H59' },
    ],
    weekdaysCustomGroups: [
      { label: 'TODOS OS DIAS', value: 'TODOS OS DIAS' },
      { label: 'SEG A SAB', value: 'SEG A SAB' },
    ],
    programs: [],
    defaultValues: {
      mainTvNetwork: '1',
      target: 'domiciliar',
      secondaryTvNetworks: ['REC', 'BAND'],
      weekdaysCustomGroup: 'TODOS OS DIAS',
      connectedTvsSumType: 'tle',
      program: '',
      startDate: '2021-01-31',
      endDate: '2021-01-31',
      share: false,
    }
  }));

  _getConsolidatedBySlotFilters = () => new Promise(res => res({
    markets: [
      { label: 'GSP - GRANDE SÃO PAULO', value:'GSP' },
      { label: 'PNC - PNT COMPLETO', value:'PNC' },
    ],
    targets: [
      { label: 'DOMICILIAR', value:'domiciliar' },
      { label: 'HOMENS', value:'h' },
      { label: 'MULHERES', value:'m' },
    ],
    tvNetworks: [
      { label: 'TV GLOBO', value:'GLOBO' },
      { label: 'REDE RECORD', value:'REC' },
      { label: 'SBT', value:'SBT' },
      { label: 'BANDEIRANTES', value:'BAND' },
    ],
    connectedTvsSumTypes: [
      { label: 'TL', value:'tl' },
      { label: 'TLE', value:'tle' },
    ],
    customTimeSlots: [
      { label: '6H as 5H59', value: '6H as 5H59'},
      { label: '6H as 12H', value: '6H as 12H' },
      { label: '12H as 5H59', value: '12H as 5H59' },
    ],
    weekdaysCustomGroups: [
      { label: 'TODOS OS DIAS', value: 'TODOS OS DIAS' },
      { label: 'SEG A SAB', value: 'SEG A SAB' },
    ],
    defaultValues: {
      market: 'GSP',
      target: 'domiciliar',
      secondaryTvNetworks: ['GLOBO','REC', 'BAND'],
      weekdaysCustomGroup: 'TODOS OS DIAS',
      customTimeSlot: '6H as 5H59',
      connectedTvsSumType: 'tle',
      equivalentPeriod: false,
    }
  }));
  _getConsolidatedBySlotByYear = async (params: ConsolidatedBySlotByYearSearchParamsDTO): Promise<ConsolidatedByYearDTO | null> => {
    console.log({ params });
    return {
      series: [
        {
          color: '#0088cc',
          name: 'Globo',
          data: [
            { x: Date.UTC(2016, 0, 1), audience: 40.876, share: 60 },
            { x: Date.UTC(2017, 0, 1), audience: 50, share: 70 },
            { x: Date.UTC(2018, 0, 1), audience: 60, share: 80 },
            { x: Date.UTC(2019, 0, 1), audience: 70, share: 90 },
          ],
        },
        {
          color: '#00aa22',
          name: 'Record',
          data: [
            { x: Date.UTC(2016, 0, 1), audience: 30, share: 50 },
            { x: Date.UTC(2017, 0, 1), audience: 40, share: 60 },
            { x: Date.UTC(2018, 0, 1), audience: 51, share: 71 },
            { x: Date.UTC(2019, 0, 1), audience: 20, share: 40 },
          ],
        },
        {
          color: '#aa0022',
          name: 'SBT',
          data: [
            { x: Date.UTC(2016, 0, 1), audience: 21, share: 41 },
            { x: Date.UTC(2017, 0, 1), audience: 14, share: 34 },
            { x: Date.UTC(2018, 0, 1), audience: 25, share: 45 },
            { x: Date.UTC(2019, 0, 1), audience: 10, share: 30 },
          ],
        },
      ],
    };
  };
  _getConsolidatedBySlotByMonth = async (params: ConsolidatedBySlotByMonthSearchParamsDTO): Promise<ConsolidatedByMonthDTO | null> => {
    return {
      series: [
        {
          data: [
            { x: Date.UTC(1970, 0, 1), audience: 12.345, share: 31 },
            { x: Date.UTC(1970, 1, 1), audience: 10, share: 25  },
            { x: Date.UTC(1970, 2, 1), audience: 15, share: 40  },
            { x: Date.UTC(1970, 3, 1), audience: 10, share: 25  },
            { x: Date.UTC(1970, 4, 1), audience: 11, share: 30  },
            { x: Date.UTC(1970, 5, 1), audience: 13, share: 32  },
            { x: Date.UTC(1970, 6, 1), audience: 12, share: 10  },
            { x: Date.UTC(1970, 7, 1), audience: 10, share: 10  },
            { x: Date.UTC(1970, 8, 1), audience: 15, share: 10  },
            { x: Date.UTC(1970, 9, 1), audience: 10, share: 10  },
            { x: Date.UTC(1970, 10, 1), audience: 11, share: 10  },
            { x: Date.UTC(1970, 11, 1), audience: 13, share: 10  },
          ],
          color: '#0088cc',
          name: 'Globo',
        },
        {
          data: [
            { x: Date.UTC(1970, 0, 1), audience: 9, share: 10 },
            { x: Date.UTC(1970, 1, 1), audience: 9, share: 10 },
            { x: Date.UTC(1970, 2, 1), audience: 7, share: 10 },
            { x: Date.UTC(1970, 3, 1), audience: 8, share: 10 },
            { x: Date.UTC(1970, 4, 1), audience: 11, share: 10 },
            { x: Date.UTC(1970, 5, 1), audience: 7, share: 10 },
            { x: Date.UTC(1970, 6, 1), audience: 9, share: 10 },
            { x: Date.UTC(1970, 7, 1), audience: 9, share: 10 },
            { x: Date.UTC(1970, 8, 1), audience: 7, share: 10 },
            { x: Date.UTC(1970, 9, 1), audience: 8, share: 10 },
            { x: Date.UTC(1970, 10, 1), audience: 11, share: 10 },
            { x: Date.UTC(1970, 11, 1), audience: 7, share: 10 }
          ],
          color: '#00aa22',
          name: 'Record'
        },
        {
          data: [
            { x: Date.UTC(1970, 0, 1), audience: 6, share: 10 },
            { x: Date.UTC(1970, 1, 1), audience: 5, share: 10 },
            { x: Date.UTC(1970, 2, 1), audience: 5, share: 10 },
            { x: Date.UTC(1970, 3, 1), audience: 4, share: 10 },
            { x: Date.UTC(1970, 4, 1), audience: 2, share: 10 },
            { x: Date.UTC(1970, 5, 1), audience: 3, share: 10 },
            { x: Date.UTC(1970, 6, 1), audience: 2, share: 10 },
            { x: Date.UTC(1970, 7, 1), audience: 6, share: 10 },
            { x: Date.UTC(1970, 8, 1), audience: 5, share: 10 },
            { x: Date.UTC(1970, 9, 1), audience: 2, share: 10 },
            { x: Date.UTC(1970, 10, 1), audience: 3, share: 10 },
            { x: Date.UTC(1970, 11, 1), audience: 4, share: 10 }
          ],
          color: '#aa0022',
          name: 'SBT'
        },
      ],
    };
  };
  _getConsolidatedBySlotByFortnight = async (params: ConsolidatedBySlotByDaySearchParamsDTO): Promise<ConsolidatedByFortnightDTO | null> => {
    console.log({ params });
    return {
      fortnights: ['1a', '2a'],
      series: [
        {
          data: [
            { name: '1a', audience: 15.1, share: 10 },
            { name: '2a', audience: 20.1, share: 10 },
          ],
          color: '#0088cc',
          name: 'Globo',
        },
        {
          data: [
            { name: '1a', audience: 7.22, share: 10 },
            { name: '2a', audience: 8.22, share: 10 },
          ],
          color: '#00aa22',
          name: 'Record',
        },
        {
          data: [
            { name: '1a', audience: 2.333, share: 10  },
            { name: '2a', audience: 5.333, share: 10  },
          ],
          color: '#aa0022',
          name: 'SBT',
        },
      ],
    };
  };
  _getConsolidatedBySlotByDay = async (params: ConsolidatedBySlotByDaySearchParamsDTO): Promise<ConsolidatedByDayDTO | null> => {
    console.log({ params });
    return {
      series: [
        {
          data: [
            { x: Date.UTC(2021, 0, 1), audience: 15.1, share: 30  },
            { x: Date.UTC(2021, 0, 2), audience: 15.1, share: 30  },
            { x: Date.UTC(2021, 0, 3), audience: 13.1, share: 30  },
            { x: Date.UTC(2021, 0, 4), audience: 14.1, share: 30  },
            { x: Date.UTC(2021, 0, 5), audience: 13.1, share: 30  },
            { x: Date.UTC(2021, 0, 6), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 7), audience: 13.1, share: 30  },
            { x: Date.UTC(2021, 0, 8), audience: 11.1, share: 30  },
            { x: Date.UTC(2021, 0, 9), audience: 10.1, share: 30  },
            { x: Date.UTC(2021, 0, 10), audience: 13.1, share: 30  },
            { x: Date.UTC(2021, 0, 11), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 12), audience: 13.1, share: 30  },
            { x: Date.UTC(2021, 0, 13), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 14), audience: 13.1, share: 30  },
            { x: Date.UTC(2021, 0, 15), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 16), audience: 13.1, share: 30  },
            { x: Date.UTC(2021, 0, 17), audience: 14.1, share: 30  },
            { x: Date.UTC(2021, 0, 18), audience: 14.1, share: 30  },
            { x: Date.UTC(2021, 0, 19), audience: 15.1, share: 30  },
            { x: Date.UTC(2021, 0, 20), audience: 18.1, share: 30  },
            { x: Date.UTC(2021, 0, 21), audience: 13.1, share: 30  },
            { x: Date.UTC(2021, 0, 22), audience: 11.1, share: 30  },
            { x: Date.UTC(2021, 0, 23), audience: 15.1, share: 30  },
            { x: Date.UTC(2021, 0, 24), audience: 15.1, share: 30  },
            { x: Date.UTC(2021, 0, 25), audience: 13.1, share: 30  },
            { x: Date.UTC(2021, 0, 26), audience: 13.1, share: 30  },
            { x: Date.UTC(2021, 0, 27), audience: 13.1, share: 30  },
            { x: Date.UTC(2021, 0, 28), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 29), audience: 11.1, share: 30  },
            { x: Date.UTC(2021, 0, 30), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 31), audience: 14.1, share: 30  },
          ],
          color: '#0088cc',
          name: 'Globo',
        },
        {
          data: [
            { x: Date.UTC(2021, 0, 1), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 2), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 3), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 4), audience: 11.1, share: 30  },
            { x: Date.UTC(2021, 0, 5), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 6), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 7), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 8), audience: 11.1, share: 30  },
            { x: Date.UTC(2021, 0, 9), audience: 10.1, share: 30  },
            { x: Date.UTC(2021, 0, 10), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 11), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 12), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 13), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 14), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 15), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 16), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 17), audience: 14.1, share: 30  },
            { x: Date.UTC(2021, 0, 18), audience: 14.1, share: 30  },
            { x: Date.UTC(2021, 0, 19), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 20), audience: 11.1, share: 30  },
            { x: Date.UTC(2021, 0, 21), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 22), audience: 11.1, share: 30  },
            { x: Date.UTC(2021, 0, 23), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 24), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 25), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 26), audience: 10.1, share: 30  },
            { x: Date.UTC(2021, 0, 27), audience: 10.1, share: 30  },
            { x: Date.UTC(2021, 0, 28), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 29), audience: 11.1, share: 30  },
            { x: Date.UTC(2021, 0, 30), audience: 12.1, share: 30  },
            { x: Date.UTC(2021, 0, 31), audience: 5.1, share: 30 },
          ],
          color: '#00aa22',
          name: 'Record'
        },
        {
          data: [
            { x: Date.UTC(2021, 0, 1), audience: 6.1, share: 30 },
            { x: Date.UTC(2021, 0, 2), audience: 6.1, share: 30 },
            { x: Date.UTC(2021, 0, 3), audience: 3.1, share: 30 },
            { x: Date.UTC(2021, 0, 4), audience: 7.1, share: 30 },
            { x: Date.UTC(2021, 0, 5), audience: 3.1, share: 30 },
            { x: Date.UTC(2021, 0, 6), audience: 6.1, share: 30 },
            { x: Date.UTC(2021, 0, 7), audience: 3.1, share: 30 },
            { x: Date.UTC(2021, 0, 8), audience: 7.1, share: 30 },
            { x: Date.UTC(2021, 0, 9), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 10), audience: 3.1, share: 30 },
            { x: Date.UTC(2021, 0, 11), audience: 6.1, share: 30 },
            { x: Date.UTC(2021, 0, 12), audience: 3.1, share: 30 },
            { x: Date.UTC(2021, 0, 13), audience: 6.1, share: 30 },
            { x: Date.UTC(2021, 0, 14), audience: 3.1, share: 30 },
            { x: Date.UTC(2021, 0, 15), audience: 6.1, share: 30 },
            { x: Date.UTC(2021, 0, 16), audience: 3.1, share: 30 },
            { x: Date.UTC(2021, 0, 17), audience: 7.1, share: 30 },
            { x: Date.UTC(2021, 0, 18), audience: 7.1, share: 30 },
            { x: Date.UTC(2021, 0, 19), audience: 6.1, share: 30 },
            { x: Date.UTC(2021, 0, 20), audience: 7.1, share: 30 },
            { x: Date.UTC(2021, 0, 21), audience: 3.1, share: 30 },
            { x: Date.UTC(2021, 0, 22), audience: 7.1, share: 30 },
            { x: Date.UTC(2021, 0, 23), audience: 6.1, share: 30 },
            { x: Date.UTC(2021, 0, 24), audience: 6.1, share: 30 },
            { x: Date.UTC(2021, 0, 25), audience: 3.1, share: 30 },
            { x: Date.UTC(2021, 0, 26), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 27), audience: 9.1, share: 30 },
            { x: Date.UTC(2021, 0, 28), audience: 6.1, share: 30 },
            { x: Date.UTC(2021, 0, 29), audience: 7.1, share: 30 },
            { x: Date.UTC(2021, 0, 30), audience: 6.1, share: 30 },
            { x: Date.UTC(2021, 0, 31), audience: 5.1, share: 30 },
          ],
          color: '#aa0022',
          name: 'SBT'
        },
      ],
    };
  }


  _getConsolidatedByProgramFilters = () => new Promise(res => res({
    markets: [
      { label: 'GSP - GRANDE SÃO PAULO', value:'GSP' },
      { label: 'PNC - PNT COMPLETO', value:'PNC' },
    ],
    targets: [
      { label: 'DOMICILIAR', value:'domiciliar' },
      { label: 'HOMENS', value:'h' },
      { label: 'MULHERES', value:'m' },
    ],
    tvNetworks: [
      { label: 'TV GLOBO', value:'GLOBO' },
      { label: 'REDE RECORD', value:'REC' },
      { label: 'SBT', value:'SBT' },
      { label: 'BANDEIRANTES', value:'BAND' },
    ],
    connectedTvsSumTypes: [
      { label: 'TL', value:'tl' },
      { label: 'TLE', value:'tle' },
    ],
    weekdaysCustomGroups: [
      { label: 'TODOS OS DIAS', value: 'TODOS OS DIAS' },
      { label: 'SEG A SAB', value: 'SEG A SAB' },
    ],
    programs: [],
    defaultValues: {
      market: 'GSP',
      target: 'domiciliar',
      mainTvNetwork: '1',
      secondaryTvNetworks: ['GLOBO','REC', 'BAND'],
      weekdaysCustomGroup: 'TODOS OS DIAS',
      connectedTvsSumType: 'tle',
      equivalentPeriod: false,
      program: '',
      startDate: '2021-06-15',
      endDate: '2021-06-15',
    }
  }));
  _getConsolidatedByProgramByYear = async (params: ConsolidatedByProgramByYearSearchParamsDTO): Promise<ConsolidatedByYearDTO | null> => {
    console.log({ params });
    return {
      series: [
        {
          color: '#0088cc',
          name: 'Globo',
          data: [
            { x: Date.UTC(2016, 0, 1), audience: 40.876, share: 60 },
            { x: Date.UTC(2017, 0, 1), audience: 50, share: 70 },
            { x: Date.UTC(2018, 0, 1), audience: 60, share: 80 },
            { x: Date.UTC(2019, 0, 1), audience: 70, share: 90 },
          ],
        },
        {
          color: '#00aa22',
          name: 'Record',
          data: [
            { x: Date.UTC(2016, 0, 1), audience: 30, share: 50 },
            { x: Date.UTC(2017, 0, 1), audience: 40, share: 60 },
            { x: Date.UTC(2018, 0, 1), audience: 51, share: 71 },
            { x: Date.UTC(2019, 0, 1), audience: 20, share: 40 },
          ],
        },
        {
          color: '#aa0022',
          name: 'SBT',
          data: [
            { x: Date.UTC(2016, 0, 1), audience: 21, share: 41 },
            { x: Date.UTC(2017, 0, 1), audience: 14, share: 34 },
            { x: Date.UTC(2018, 0, 1), audience: 25, share: 45 },
            { x: Date.UTC(2019, 0, 1), audience: 10, share: 30 },
          ],
        },
      ],
    };
  };
  _getConsolidatedByProgramByMonth = async (params: ConsolidatedByProgramByMonthSearchParamsDTO): Promise<ConsolidatedByMonthDTO | null> => {
    console.log({ params });
    return {
      series: [
        {
          data: [
            { x: Date.UTC(1970, 0, 1), audience: 12.3, share: 10 },
            { x: Date.UTC(1970, 1, 1), audience: 10, share: 10 },
            { x: Date.UTC(1970, 2, 1), audience: 15, share: 10 },
            { x: Date.UTC(1970, 3, 1), audience: 10, share: 10 },
            { x: Date.UTC(1970, 4, 1), audience: 11, share: 10 },
            { x: Date.UTC(1970, 5, 1), audience: 13, share: 10 },
            { x: Date.UTC(1970, 6, 1), audience: 12, share: 10 },
            { x: Date.UTC(1970, 7, 1), audience: 10, share: 10 },
            { x: Date.UTC(1970, 8, 1), audience: 15, share: 10 },
            { x: Date.UTC(1970, 9, 1), audience: 10, share: 10 },
            { x: Date.UTC(1970, 10, 1), audience: 11, share: 10 },
            { x: Date.UTC(1970, 11, 1), audience: 13, share: 10 },
          ],
          color: '#0088cc',
          name: 'Globo',
        },
        {
          data: [
            { x: Date.UTC(1970, 0, 1), audience: 9, share: 10 },
            { x: Date.UTC(1970, 1, 1), audience: 9, share: 10 },
            { x: Date.UTC(1970, 2, 1), audience: 7, share: 10 },
            { x: Date.UTC(1970, 3, 1), audience: 8, share: 10 },
            { x: Date.UTC(1970, 4, 1), audience: 11, share: 10 },
            { x: Date.UTC(1970, 5, 1), audience: 7, share: 10 },
            { x: Date.UTC(1970, 6, 1), audience: 9, share: 10 },
            { x: Date.UTC(1970, 7, 1), audience: 9, share: 10 },
            { x: Date.UTC(1970, 8, 1), audience: 7, share: 10 },
            { x: Date.UTC(1970, 9, 1), audience: 8, share: 10 },
            { x: Date.UTC(1970, 10, 1), audience: 11, share: 10 },
            { x: Date.UTC(1970, 11, 1), audience: 7, share: 10 },
        ],
          color: '#00aa22',
          name: 'Record'
        },
        {
          data: [
            { x: Date.UTC(1970, 0, 1), audience: 6, share: 10 },
            { x: Date.UTC(1970, 1, 1), audience: 5, share: 10 },
            { x: Date.UTC(1970, 2, 1), audience: 5, share: 10 },
            { x: Date.UTC(1970, 3, 1), audience: 4, share: 10 },
            { x: Date.UTC(1970, 4, 1), audience: 2, share: 10 },
            { x: Date.UTC(1970, 5, 1), audience: 3, share: 10 },
            { x: Date.UTC(1970, 6, 1), audience: 2, share: 10 },
            { x: Date.UTC(1970, 7, 1), audience: 6, share: 10 },
            { x: Date.UTC(1970, 8, 1), audience: 5, share: 10 },
            { x: Date.UTC(1970, 9, 1), audience: 2, share: 10 },
            { x: Date.UTC(1970, 10, 1), audience: 3, share: 10 },
            { x: Date.UTC(1970, 11, 1), audience: 4, share: 10 },
          ],
          color: '#aa0022',
          name: 'SBT'
        },
      ],
    };
  };
  _getConsolidatedByProgramByDay = async (params: ConsolidatedByProgramByDaySearchParamsDTO): Promise<ConsolidatedByDayDTO | null> => {
    console.log({ params });
    return {
      series: [
        {
          data: [
            { x: Date.UTC(2021, 0, 1), audience: 15.1, share: 10  },
            { x: Date.UTC(2021, 0, 2), audience: 15.1, share: 10  },
            { x: Date.UTC(2021, 0, 3), audience: 13.1, share: 10  },
            { x: Date.UTC(2021, 0, 4), audience: 14.1, share: 10  },
            { x: Date.UTC(2021, 0, 5), audience: 13.1, share: 10  },
            { x: Date.UTC(2021, 0, 6), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 7), audience: 13.1, share: 10  },
            { x: Date.UTC(2021, 0, 8), audience: 11.1, share: 10  },
            { x: Date.UTC(2021, 0, 9), audience: 10.1, share: 10  },
            { x: Date.UTC(2021, 0, 10), audience: 13.1, share: 10  },
            { x: Date.UTC(2021, 0, 11), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 12), audience: 13.1, share: 10  },
            { x: Date.UTC(2021, 0, 13), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 14), audience: 13.1, share: 10  },
            { x: Date.UTC(2021, 0, 15), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 16), audience: 13.1, share: 10  },
            { x: Date.UTC(2021, 0, 17), audience: 14.1, share: 10  },
            { x: Date.UTC(2021, 0, 18), audience: 14.1, share: 10  },
            { x: Date.UTC(2021, 0, 19), audience: 15.1, share: 10  },
            { x: Date.UTC(2021, 0, 20), audience: 18.1, share: 10  },
            { x: Date.UTC(2021, 0, 21), audience: 13.1, share: 10  },
            { x: Date.UTC(2021, 0, 22), audience: 11.1, share: 10  },
            { x: Date.UTC(2021, 0, 23), audience: 15.1, share: 10  },
            { x: Date.UTC(2021, 0, 24), audience: 15.1, share: 10  },
            { x: Date.UTC(2021, 0, 25), audience: 13.1, share: 10  },
            { x: Date.UTC(2021, 0, 26), audience: 13.1, share: 10  },
            { x: Date.UTC(2021, 0, 27), audience: 13.1, share: 10  },
            { x: Date.UTC(2021, 0, 28), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 29), audience: 11.1, share: 10  },
            { x: Date.UTC(2021, 0, 30), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 31), audience: 14.1, share: 10  },
          ],
          color: '#0088cc',
          name: 'Globo',
        },
        {
          data: [
            { x: Date.UTC(2021, 0, 1), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 2), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 3), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 4), audience: 11.1, share: 10  },
            { x: Date.UTC(2021, 0, 5), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 6), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 7), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 8), audience: 11.1, share: 10  },
            { x: Date.UTC(2021, 0, 9), audience: 10.1, share: 10  },
            { x: Date.UTC(2021, 0, 10), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 11), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 12), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 13), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 14), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 15), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 16), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 17), audience: 14.1, share: 10  },
            { x: Date.UTC(2021, 0, 18), audience: 14.1, share: 10  },
            { x: Date.UTC(2021, 0, 19), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 20), audience: 11.1, share: 10  },
            { x: Date.UTC(2021, 0, 21), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 22), audience: 11.1, share: 10  },
            { x: Date.UTC(2021, 0, 23), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 24), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 25), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 26), audience: 10.1, share: 10  },
            { x: Date.UTC(2021, 0, 27), audience: 10.1, share: 10  },
            { x: Date.UTC(2021, 0, 28), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 29), audience: 11.1, share: 10  },
            { x: Date.UTC(2021, 0, 30), audience: 12.1, share: 10  },
            { x: Date.UTC(2021, 0, 31), audience: 5.1 , share: 10 },
          ],
          color: '#00aa22',
          name: 'Record'
        },
        {
          data: [
            { x: Date.UTC(2021, 0, 1), audience: 6.1 , share: 10 },
            { x: Date.UTC(2021, 0, 2), audience: 6.1 , share: 10 },
            { x: Date.UTC(2021, 0, 3), audience: 3.1 , share: 10 },
            { x: Date.UTC(2021, 0, 4), audience: 7.1 , share: 10 },
            { x: Date.UTC(2021, 0, 5), audience: 3.1 , share: 10 },
            { x: Date.UTC(2021, 0, 6), audience: 6.1 , share: 10 },
            { x: Date.UTC(2021, 0, 7), audience: 3.1 , share: 10 },
            { x: Date.UTC(2021, 0, 8), audience: 7.1 , share: 10 },
            { x: Date.UTC(2021, 0, 9), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 10), audience: 3.1 , share: 10 },
            { x: Date.UTC(2021, 0, 11), audience: 6.1 , share: 10 },
            { x: Date.UTC(2021, 0, 12), audience: 3.1 , share: 10 },
            { x: Date.UTC(2021, 0, 13), audience: 6.1 , share: 10 },
            { x: Date.UTC(2021, 0, 14), audience: 3.1 , share: 10 },
            { x: Date.UTC(2021, 0, 15), audience: 6.1 , share: 10 },
            { x: Date.UTC(2021, 0, 16), audience: 3.1 , share: 10 },
            { x: Date.UTC(2021, 0, 17), audience: 7.1 , share: 10 },
            { x: Date.UTC(2021, 0, 18), audience: 7.1 , share: 10 },
            { x: Date.UTC(2021, 0, 19), audience: 6.1 , share: 10 },
            { x: Date.UTC(2021, 0, 20), audience: 7.1 , share: 10 },
            { x: Date.UTC(2021, 0, 21), audience: 3.1 , share: 10 },
            { x: Date.UTC(2021, 0, 22), audience: 7.1 , share: 10 },
            { x: Date.UTC(2021, 0, 23), audience: 6.1 , share: 10 },
            { x: Date.UTC(2021, 0, 24), audience: 6.1 , share: 10 },
            { x: Date.UTC(2021, 0, 25), audience: 3.1 , share: 10 },
            { x: Date.UTC(2021, 0, 26), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 27), audience: 9.1 , share: 10 },
            { x: Date.UTC(2021, 0, 28), audience: 6.1 , share: 10 },
            { x: Date.UTC(2021, 0, 29), audience: 7.1 , share: 10 },
            { x: Date.UTC(2021, 0, 30), audience: 6.1 , share: 10 },
            { x: Date.UTC(2021, 0, 31), audience: 5.1 , share: 10 },
          ],
          color: '#aa0022',
          name: 'SBT'
        },
      ],
      programs: [
        {
          day: Date.UTC(2021, 0, 1),
          programs:[
            {
              id: 'A',
              name: 'Jornal Nacional',
              tvNetwork: 'GLOBO',
              color: '#0000aa',
              exhibitionPercentage: 100,
            },
            {
              id: 'B',
              name: 'Religioso',
              tvNetwork: 'REC',
              color: '#aa0000',
              exhibitionPercentage: 80,
            },
            {
              id: 'C',
              name: 'Programa 2',
              tvNetwork: 'REC',
              color: '#aa0000',
              exhibitionPercentage: 20,
            },
            {
              id: 'D',
              name: 'Topa tudo por dinheiro',
              tvNetwork: 'SBT',
              color: '#00aa00',
              exhibitionPercentage: 100,
            },
          ]
        },
        {
          day: Date.UTC(2021, 0, 2),
          programs:[
            {
              id: 'E',
              name: 'Jornal Nacional',
              tvNetwork: 'GLOBO',
              color: '#0000aa',
              exhibitionPercentage: 100,
            },
            {
              id: 'F',
              name: 'programa 3',
              tvNetwork: 'REC',
              color: '#aa0000',
              exhibitionPercentage: 50,
            },
            {
              id: 'G',
              name: 'Programa 4',
              tvNetwork: 'REC',
              color: '#aa0000',
              exhibitionPercentage: 50,
            },
            {
              id: 'H',
              name: 'Maria do Bairro',
              tvNetwork: 'SBT',
              color: '#00aa00',
              exhibitionPercentage: 100,
            },
          ]
        },
      ],
    };
  };
}
