import { Action, Module, VuexModule } from 'vuex-module-decorators';
import SIABffService from '@/services/sia-bff.service';
import {
  DailyGridDTO, DailyGridSearchParamsDTO, DailyGridFiltersDTO,
  DailyGridExportParamsDTO, DailyGridAverageDTO
} from '@/data/dto/daily-grid.dto';

@Module
export default class DailyGridModule extends VuexModule {
  bffService = new SIABffService();

  @Action
  getDailyGrid(params: DailyGridSearchParamsDTO): Promise<DailyGridDTO | null> {
    return this.bffService
      .getDailyGrid(params);
  }

  @Action
  getDailyGridFilters(): Promise<DailyGridFiltersDTO> {
    return this.bffService
      .getDailyGridFilters();
  }

  @Action
  getDailyGridAverage(params: DailyGridSearchParamsDTO): Promise<DailyGridAverageDTO | null> {
    return this.bffService
      .getDailyGridAverage(params);
  }

  @Action
  getDailyGridExcel(params: DailyGridExportParamsDTO): Promise<ArrayBuffer> {
    return this.bffService
      .getDailyGridExcel(params);
  }
}
