export default class UIUtil {
  //??? há alguma forma melhor de resolver essa questão?
  static generateImagePathbyFileName(name:string | undefined): string {
    if (name===undefined) { console.log('sem img'); return '';}
    try {
      return require('@/assets/img/' + name);
    }
    catch(e) {
      console.log(`Imagem ${name} não encontrada.`, e);
      return '';
    }
  }

  static generateGradient(colorArray:string[] = [], angle = 145): string {
    if (colorArray.length === 0) return '';
    if (colorArray.length === 1) return colorArray[0];
    
    const increment:number = 100 / (colorArray.length - 1);
    let currentProgress = 0;
    let gradientColors = '';

    colorArray.forEach((color, index) => {
      if(index!==0) gradientColors += ', '; 
      gradientColors += `${color} ${currentProgress}%`;
      currentProgress += increment;
    });
    
    return `transparent linear-gradient(${angle}deg, ${gradientColors}) 0% 0% no-repeat padding-box`;
  }

  static hexToRgb(hex: string): number[] | null {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : null;
  }
  
  static lightOrDark(hex: string): 'light' | 'dark' {
    if(!hex) return 'dark';

    //converte hex para rgb
    const rgb = UIUtil.hexToRgb(hex);
    if(!rgb) return 'dark';    
    const [r,g,b] = [...rgb];

    const hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
    return hsp>127.5 ? 'light' : 'dark';
  }
}