import { Action, Module, VuexModule } from 'vuex-module-decorators';
import SIABffService from '@/services/sia-bff.service';
import { RankedTvNetworkDTO, RankingOfTvNetworksExportParamsDTO, RankingOfTvNetworksFiltersDTO, RankingOfTvNetworksSearchParamsDTO } from '@/data/dto/ranking-of-tv-networks.dto';

@Module
export default class RankingOfTvNetworksModule extends VuexModule {
  bffService = new SIABffService();

  @Action
  getRankingOfTvNetworksFilters():
    Promise<RankingOfTvNetworksFiltersDTO> {
    return this.bffService
      .getRankingOfTvNetworksFilters();
  }

  @Action
  getRankingOfTvNetworks(params: RankingOfTvNetworksSearchParamsDTO):
    Promise<RankedTvNetworkDTO[] | null> {
    return this.bffService
      .getRankingOfTvNetworks(params);
  }

  @Action
  getRankingOfTvNetworksExcel(params: RankingOfTvNetworksExportParamsDTO):
    Promise<ArrayBuffer> {
    return this.bffService
    .getRankingOfTvNetworksExcel(params);
  }
}
