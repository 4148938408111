
import { Component, Vue, Watch } from "vue-property-decorator";
import Menu from "@/components/Menu.vue";
import PWAButton from "@/components/PWAInstallationButton.vue";
import Alert from "@/components/Alert.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import VueAnalytics from "vue-analytics";
import router from "./router";

@Component({
  components: {
    Menu,
    PWAButton,
    Alert,
    LoadingOverlay,
  },
})
export default class App extends Vue {
  get hasCurrentUserData(): boolean {
    return !!this.$store.state.user.currentUser;
  }
  @Watch("hasCurrentUserData")
  onHasCurrentUserData(hasCurrentUserData: boolean): void {
    try {
      const token = localStorage.getItem("token");
      if (hasCurrentUserData && token) {
        const userRoot = JSON.parse(atob(token.split(".")[1]));
        Vue.use(VueAnalytics, {
          id: process.env.VUE_APP_ANALYTICS_ID,
          autoTracking: {
            pageviewOnLoad: false,
          },
          batch: {
            enabled: true, // enable/disable
            amount: 1, // amount of events fired
            delay: 1500, // delay in milliseconds
          },
          router,
          set: [{ field: "userId", value: userRoot.email }],
        });
        this.$ga.query("set", { dimension1: userRoot.email });
      }
    } catch (error) {
      console.error(error);
      return;
    }
  }
}
