import router from '@/router';
import UIUtil from '@/utils/ui.util';

//set element color to match route custom-color meta attribute
export default {
  inserted(el:HTMLElement): void {
    let customColors: string;
    try {
      customColors = router.currentRoute.meta ? UIUtil.generateGradient(router.currentRoute.meta.customColors) : '#6c757d';
    } catch(e) {
      customColors = '#6c757d';
    }
    el.style.setProperty('background', customColors, 'important');
    el.style.setProperty('color', "#ffffff", 'important');
  }
}