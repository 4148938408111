import router from '@/router';

//set element color to match route custom-color meta attribute
export default {
  inserted(el:HTMLElement): void {
    let customColors: string;
    try {
      customColors = router.currentRoute.meta ? router.currentRoute.meta.customColors[0] : '#6c757d';
    } catch(e) {
      customColors = '#6c757d';
    }
    el.style.setProperty('background-color', customColors, 'important');
    el.style.setProperty('border-color', customColors, 'important');
    el.style.setProperty('color', "#ffffff", 'important');
  }
}