
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class PWAInstallationButton extends Vue {
  private pwaInstallBtnVisibility = 'none';
  private pwaInstaller:any = undefined;

  get pwaInstallationDenied(): boolean {
    return this.$store.state.system.pwaInstallationDenied;
  }

  get appInitials(): string {
    return this.$store.state.system.appInitials;
  }

  created(): void {
    let installPrompt:any;
    
    window.addEventListener('beforeinstallprompt', e => {
      //prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      if (!this.pwaInstallationDenied) {
        //stash the event so it can be triggered later
        installPrompt = e;
        //show button
        this.pwaInstallBtnVisibility = 'block';
      }
    });

    this.pwaInstaller = () => {
      //hide button
      this.pwaInstallBtnVisibility = 'none';
      //show the prompt
      installPrompt.prompt();
      //wait for the user to respond to the prompt
      installPrompt.userChoice.then((result: any) => {
        if(result.outcome === 'accepted') {
          console.log('user accepted');
        } else {
          console.log('user denied');
          this.$store.commit('declinePWAInstallation');
        }
        installPrompt = null;
      });
    };
  }
}
