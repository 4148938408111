import Vue from 'vue';
import Vuex from 'vuex';
import SystemModule from '@/store/modules/system';
import UserModule from '@/store/modules/user';
import GroupModule from '@/store/modules/group';
import ConfigModule from '@/store/modules/config';
import DailyAudienceModule from '@/store/modules/researchs/daily-audience';
import DailyGridModule from '@/store/modules/researchs/daily-grid';
import MinuteByMinuteModule from '@/store/modules/researchs/minute-by-minute';
import ConsolidatedByMarketModule from '@/store/modules/researchs/consolidated-by-market';
import ConsolidatedBySlotModule from '@/store/modules/researchs/consolidated-by-slot';
import ConsolidatedByProgramModule from '@/store/modules/researchs/consolidated-by-program';
import FilterModule from '@/store/modules/researchs/filters';
import RankingOfTvNetworksModule from './modules/researchs/ranking-of-tv-networks';
import RankingOfProgramsModule from './modules/researchs/ranking-of-programs';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    system: SystemModule,
    user: UserModule,
    group: GroupModule,
    config: ConfigModule,
    dailyAudience: DailyAudienceModule,
    dailyGrid: DailyGridModule,
    minuteByMinute: MinuteByMinuteModule,
    consolidatedByMarket: ConsolidatedByMarketModule,
    consolidatedBySlot: ConsolidatedBySlotModule,
    consolidatedByProgram: ConsolidatedByProgramModule,
    rankingOfTvNetworksModule: RankingOfTvNetworksModule,
    rankingOfProgramsModule: RankingOfProgramsModule,
    filter: FilterModule,
  }
});