
import { RouteConfig } from 'vue-router';
import { UserDTO } from '@/data/dto/user.dto';

export default class RouterUtil {

  static identifyUserAllowedRoutes(user: UserDTO, routes: RouteConfig[]): RouteConfig[] {
    if(!routes) return [];
    return routes.map((route) => {
      if(route.children) {
        route.children = RouterUtil.identifyUserAllowedRoutes(user, route.children);
      }
      if (!route.meta) route.meta = {};
      route.meta.userAllowed = !RouterUtil.routeRequiresPermission(route) || RouterUtil.userHasPermission(route, user);
      return route;
    });
  }

  static getNavigationRoutes(routes: RouteConfig[]): RouteConfig[] {
    if(!routes) return [];
    return routes.filter((route: RouteConfig) => {
      if(route.children) {
        route.children = RouterUtil.getNavigationRoutes(route.children);
      }
      return RouterUtil.isVisibleOnNavigation(route);
    });
  }

  // Pega apenas o valor do path após a "/". Necessário para identificar rotas aninhadas
  static sanitizePath(path: string): string {
    const pathArray = path.split('/');
    return pathArray[pathArray.length - 1];
  }

  static getRouteByPath(path: string, routes: RouteConfig[] = []): RouteConfig | undefined {
    if (!path) return undefined;
    for(let i=0; i < routes.length; i++) {
      const route = routes[i];
      if (RouterUtil.sanitizePath(route.path) === RouterUtil.sanitizePath(path)) return {...route};
      if (route.children) {
        const child = RouterUtil.getRouteByPath(path, route.children);
        if (child) return {...child};
      }
    }
  }

  static getChildrenByParentPath(path: string, routes: RouteConfig[]): RouteConfig[] {
    const currentRoute = RouterUtil.getRouteByPath(path, routes);
    return currentRoute && currentRoute.children ? [...currentRoute.children] : [];
  }

  static isNextRouteAllowed = (toPath: string, userRoutes: RouteConfig[]) => {
    const nextRoute = RouterUtil.getRouteByPath(toPath, userRoutes);
    return nextRoute && nextRoute.meta && nextRoute.meta.userAllowed;
  }

  static isVisibleOnNavigation = (route: RouteConfig) => !route.meta || !route.meta.hiddenOnNavigation;

  static routeRequiresPermission = (route: RouteConfig) => route.meta && route.meta.requiresPermission;

  static userHasPermission = (route: RouteConfig, user: UserDTO) => {
    if (!route.meta || !route.meta.requiresPermission) return true;
    return user.policies && Object.keys(user.policies).includes(route.meta.requiresPermission);
  };  
}
