export const getDailyAudienceMock = {
  program: 'Jornal Nacional',
  series: [
    {
      data: [
        {x: Date.UTC(1970, 10, 1), audience: 28.999, share: 10, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 2), audience: 24.999, share: 34, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 3), audience: 26.999, share: 53, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 4), audience: 32.999, share: 21, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 5), audience: 25.999, share: 10, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 6), audience: 28.999, share: 4, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 7), audience: 24.999, share: 87, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 8), audience: 32.999, share: 43, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 9), audience: 32.999, share: 9, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 10), audience: 25.999, share: 103, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 11), audience: 28.999, share: 42, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 12), audience: 24.999, share: 11, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 13), audience: 32.999, share: 73, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 14), audience: 32.999, share: 4, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 15), audience: 25.999, share: 53, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 16), audience: 28.999, share: 10, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 17), audience: 24.999, share: 86, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 18), audience: 32.999, share: 10, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 19), audience: 32.999, share: 21, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 20), audience: 25.999, share: 10, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 21), audience: 28.999, share: 52, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 22), audience: 24.999, share: 32, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 23), audience: 32.999, share: 10, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 24), audience: 32.999, share: 65, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 25), audience: 25.999, share: 41, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 26), audience: 28.999, share: 12, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 27), audience: 24.999, share: 45, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 28), audience: 32.999, share: 32, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 29), audience: 32.999, share: 10, viewersProjection: 1234567},
        {x: Date.UTC(1970, 10, 30), audience: 25.999, share: 24, viewersProjection: 1234567},
      ],
      color: '#0088cc',
      name: 'Globo',
    },
    {
      data: [
        {x: Date.UTC(1970, 10, 1), audience: 18.999, share: 25},
        {x: Date.UTC(1970, 10, 2), audience: 14.999, share: 35},
        {x: Date.UTC(1970, 10, 3), audience: 12.999, share: 64},
        {x: Date.UTC(1970, 10, 4), audience: 12.999, share: 74},
        {x: Date.UTC(1970, 10, 5), audience: 15.999, share: 12},
        {x: Date.UTC(1970, 10, 6), audience: 18.999, share: 43},
        {x: Date.UTC(1970, 10, 7), audience: 14.999, share: 51},
        {x: Date.UTC(1970, 10, 8), audience: 12.999, share: 10},
        {x: Date.UTC(1970, 10, 9), audience: 12.999, share: 25},
        {x: Date.UTC(1970, 10, 10), audience: 15.999, share: 34},
        {x: Date.UTC(1970, 10, 11), audience: 18.999, share: 14},
        {x: Date.UTC(1970, 10, 12), audience: 14.999, share: 53},
        {x: Date.UTC(1970, 10, 13), audience: 12.999, share: 21},
        {x: Date.UTC(1970, 10, 14), audience: 12.999, share: 41},
        {x: Date.UTC(1970, 10, 15), audience: 15.999, share: 45},
        {x: Date.UTC(1970, 10, 16), audience: 18.999, share: 23},
        {x: Date.UTC(1970, 10, 17), audience: 14.999, share: 10},
        {x: Date.UTC(1970, 10, 18), audience: 12.999, share: 62},
        {x: Date.UTC(1970, 10, 19), audience: 12.999, share: 46},
        {x: Date.UTC(1970, 10, 20), audience: 15.999, share: 23},
        {x: Date.UTC(1970, 10, 21), audience: 18.999, share: 36},
        {x: Date.UTC(1970, 10, 22), audience: 14.999, share: 32},
        {x: Date.UTC(1970, 10, 23), audience: 12.999, share: 14},
        {x: Date.UTC(1970, 10, 24), audience: 12.999, share: 27},
        {x: Date.UTC(1970, 10, 25), audience: 15.999, share: 34},
        {x: Date.UTC(1970, 10, 26), audience: 18.999, share: 23},
        {x: Date.UTC(1970, 10, 27), audience: 14.999, share: 10},
        {x: Date.UTC(1970, 10, 28), audience: 12.999, share: 14},
        {x: Date.UTC(1970, 10, 29), audience: 12.999, share: 64},
        {x: Date.UTC(1970, 10, 30), audience: 15.999, share: 10},
      ],
      color: '#7cd66b',
      name: 'SBT',
      visible: true,
      // dataLabels : {
      //   formatter: function(): number {
      //     return Math.round(this.y);
      //   }
      // }
    }
  ]
}