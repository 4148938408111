
import { Component, Vue } from 'vue-property-decorator';
import { BAlert } from 'bootstrap-vue';

@Component({
  components: {
    BAlert,
  }
})
export default class AlertSiaNew extends Vue { }
