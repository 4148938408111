import { Action, Module, VuexModule } from 'vuex-module-decorators';
import SIABffService from '@/services/sia-bff.service';
import { 
  GeneralConfigDTO, 
  tvNetworkResponse, 
  marketResponse,
  NotificationsConfigDTO,
  DailyGridConfigDTO,
  DailyAudienceConfigDTO,
  MinuteByMinuteConfigDTO,
  ConsolidatedConfigDTO,
  RankingConfigDTO,
} from '@/data/dto/config.dto';

@Module
export default class ConfigModule extends VuexModule {
  bffService = new SIABffService();

  @Action
  getGeneralConfigs(): Promise<GeneralConfigDTO> {
    return this.bffService
      .getGeneralConfigs();      
  }

  @Action
  updateGeneralConfigs(configs: GeneralConfigDTO): Promise<void> {
    return this.bffService
      .updateGeneralConfigs(configs);      
  }

  @Action
  getTvNetworkConfigs(): Promise<tvNetworkResponse> {
    return this.bffService
      .getTvNetworkConfigs();      
  }

  @Action
  updateTvNetworkConfigs(configs: tvNetworkResponse): Promise<void> {
    return this.bffService
      .updateTvNetworkConfigs(configs);      
  }

  @Action
  getMarketConfigs(): Promise<marketResponse> {
    return this.bffService
      .getMarketConfigs();      
  }

  @Action
  updateMarketConfigs(configs: marketResponse): Promise<void> {
    return this.bffService
      .updateMarketConfigs(configs);      
  }

  @Action
  getNotificationConfigs(user: string): Promise<NotificationsConfigDTO> {
    return this.bffService
      .getNotificationConfigs(user);      
  }

  @Action
  updateNotificationConfigs(payload: { user: string, configs: NotificationsConfigDTO }): Promise<void> {
    return this.bffService
      .updateNotificationConfigs(payload.user, payload.configs);      
  }

  @Action
  getDailyGridConfigs(): Promise<DailyGridConfigDTO> {
    return this.bffService
      .getDailyGridConfigs();      
  }

  @Action
  updateDailyGridConfigs(configs: DailyGridConfigDTO): Promise<void> {
    return this.bffService
      .updateDailyGridConfigs(configs);      
  }

  @Action
  getRankingConfigs(): Promise<RankingConfigDTO> {
    return this.bffService
      .getRankingConfigs();      
  }

  @Action
  updateRankingConfigs(configs: RankingConfigDTO): Promise<void> {
    return this.bffService
      .updateRankingConfigs(configs);      
  }

  @Action
  getDailyAudienceConfigs(): Promise<DailyAudienceConfigDTO> {
    return this.bffService
      .getDailyAudienceConfigs();      
  }

  @Action
  updateDailyAudienceConfigs(configs: DailyAudienceConfigDTO): Promise<void> {
    return this.bffService
      .updateDailyAudienceConfigs(configs);      
  }

  @Action
  getMinuteByMinuteConfigs(): Promise<MinuteByMinuteConfigDTO> {
    return this.bffService
      .getMinuteByMinuteConfigs();      
  }

  @Action
  updateMinuteByMinuteConfigs(configs: MinuteByMinuteConfigDTO): Promise<void> {
    return this.bffService
      .updateMinuteByMinuteConfigs(configs);      
  }

  @Action
  getConsolidatedConfigs(): Promise<ConsolidatedConfigDTO> {
    return this.bffService
      .getConsolidatedConfigs();      
  }

  @Action
  updateConsolidatedConfigs(configs: ConsolidatedConfigDTO): Promise<void> {
    return this.bffService
      .updateConsolidatedConfigs(configs);      
  }
}