import { RankedProgramDTO, RankingOfProgramsExportParamsDTO, RankingOfProgramsFiltersDTO, RankingOfProgramsSearchParamsDTO } from '../../../data/dto/ranking-of-programs';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import SIABffService from '@/services/sia-bff.service';

@Module
export default class RankingOfProgramsModule extends VuexModule {
  bffService = new SIABffService();

  @Action
  getRankingOfProgramsFilters():
    Promise<RankingOfProgramsFiltersDTO> {
    return this.bffService
      .getRankingOfProgramsFilters();
  }

  @Action
  getRankingOfPrograms(params: RankingOfProgramsSearchParamsDTO):
    Promise<RankedProgramDTO[] | null> {
    return this.bffService
      .getRankingOfPrograms(params);
  }

  @Action
  getRankingOfProgramsExcel(params: RankingOfProgramsExportParamsDTO):
    Promise<ArrayBuffer| null> {
    return this.bffService
      .getRankingOfProgramsExcel(params);
  }
}
