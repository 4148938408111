import { Action, Module, VuexModule } from 'vuex-module-decorators';
import SIABffService from '@/services/sia-bff.service';
import {
  MinuteByMinuteDTO,
  MinuteByMinuteSearchParamsDTO,
  MinuteByMinuteFiltersDTO,
  MinuteByMinuteTvNetworkGridsDTO,
  MinuteByMinuteExportParamsDTO,
} from '@/data/dto/minute-by-minute.dto';

@Module
export default class MinuteByMinuteModule extends VuexModule {
  bffService = new SIABffService();

  @Action
  getMinuteByMinute(params: MinuteByMinuteSearchParamsDTO): Promise<MinuteByMinuteDTO | null> {
    return this.bffService
      .getMinuteByMinute(params);
  }

  @Action
  getMinuteByMinuteFilters(): Promise<MinuteByMinuteFiltersDTO> {
    return this.bffService
      .getMinuteByMinuteFilters();
  }

  @Action
  getMinuteByMinuteTvNetworkGrids(params: MinuteByMinuteSearchParamsDTO): Promise<MinuteByMinuteTvNetworkGridsDTO | null> {
    return this.bffService
      .getMinuteByMinuteTvNetworkGrids(params);
  }

  @Action
  getMinuteByMinuteExcel(params: MinuteByMinuteExportParamsDTO): Promise<ArrayBuffer | null> {
    return this.bffService
      .getMinuteByMinuteExcel(params);
  }
}
