
import { Component, Prop, Vue } from 'vue-property-decorator';
import UIUtil from '@/utils/ui.util';

export interface ISectionCardData {
  link: string;
  imageFile?: string;
  title?: string;
  text?: string;
  customColors? : string[];
  fixedHeight?: number;
  externalLink?: boolean;
  blocked?: boolean;
  isNew?: boolean;
}

@Component
export default class SectionCard extends Vue {  
  @Prop(String) private title?: string;
  @Prop(String) private text?: string;
  @Prop(String) private link!: string;
  @Prop(String) private imageFile?: string;
  @Prop(Array) private customColors?: string[];
  @Prop(Number) private fixedHeight?: number;
  @Prop({ default: false }) private externalLink!: boolean;
  @Prop({ default: false }) private blocked!: boolean;
  @Prop({ default: false }) private isNew!: boolean;


  get cardImageSize(): string {
    return this.fixedHeight ? `${this.fixedHeight}px` : 'auto';
  }
  
  generateSrc(fileName: string): string {
    return UIUtil.generateImagePathbyFileName(fileName);
  }

  generateGradient(colorArray:string[]): string {
    return UIUtil.generateGradient(colorArray);
  }

  goToLink(link: string, blocked = false, externalLink = false): void {
    if (blocked) return;
    if (externalLink) {
      window.open(link, '_blank');
    } else {
      this.$router.push(link);
    }
  }
}
