
import { Component, Vue } from 'vue-property-decorator';
import { IAlert } from '@/store/modules/system';

@Component({})
export default class Alert extends Vue {
  get alert():IAlert  {
    return this.$store.state.system.alert;
  }

  hide(): void {
    this.$store.commit('hideAlert');
  }
}
