import { Action, Module, VuexModule } from 'vuex-module-decorators';
import SIABffService from '@/services/sia-bff.service';
import {
  ConsolidatedByMarketDTO,
  ConsolidatedByMarketBySlotSearchParamsDTO,
  ConsolidatedByMarketBySlotFiltersDTO,
  ConsolidatedByMarketByProgramSearchParamsDTO,
  ConsolidatedByMarketByProgramFiltersDTO,
  ConsolidatedByMarketBySlotExportParams,
  ConsolidatedByMarketByProgramExportParams,
} from '@/data/dto/consolidated-by-market.dto';

@Module
export default class ConsolidatedByMarketModule extends VuexModule {
  bffService = new SIABffService();

  @Action
  getConsolidatedByMarketBySlot(params: ConsolidatedByMarketBySlotSearchParamsDTO): Promise<ConsolidatedByMarketDTO | null> {
    return this.bffService
      .getConsolidatedByMarketBySlot(params);
  }

  @Action
  getConsolidatedByMarketBySlotFilters(): Promise<ConsolidatedByMarketBySlotFiltersDTO> {
    return this.bffService
      .getConsolidatedByMarketBySlotFilters();
  }

  @Action
  getConsolidatedByMarketBySlotExcel(params: ConsolidatedByMarketBySlotExportParams): Promise<ArrayBuffer | null> {
    return this.bffService
      .getConsolidatedByMarketBySlotExcel(params);
  }

  @Action
  getConsolidatedByMarketByProgram(params: ConsolidatedByMarketByProgramSearchParamsDTO): Promise<ConsolidatedByMarketDTO | null> {
    return this.bffService
      .getConsolidatedByMarketByProgram(params);
  }

  @Action
  getConsolidatedByMarketByProgramFilters(): Promise<ConsolidatedByMarketByProgramFiltersDTO> {
    return this.bffService
      .getConsolidatedByMarketByProgramFilters();
  }

  @Action
  getConsolidatedByMarketByProgramExcel(params: ConsolidatedByMarketByProgramExportParams): Promise<ArrayBuffer | null> {
    return this.bffService
      .getConsolidatedByMarketByProgramExcel(params);
  }
}
