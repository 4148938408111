import { Action, Module, VuexModule } from 'vuex-module-decorators';
import SIABffService from '@/services/sia-bff.service';
import { ConsolidatedByDayDTO } from '@/data/dto/consolidated-by-day.dto';
import { ConsolidatedByMonthDTO } from '@/data/dto/consolidated-by-month.dto';
import { ConsolidatedByYearDTO } from '@/data/dto/consolidated-by-year.dto';
import {
  ConsolidatedByProgramFiltersDTO,
  ConsolidatedByProgramByYearSearchParamsDTO,
  ConsolidatedByProgramByMonthSearchParamsDTO,
  ConsolidatedByProgramByDaySearchParamsDTO, ConsolidatedByProgramExportParams
} from '@/data/dto/consolidated-by-program.dto';

@Module
export default class ConsolidatedByProgramModule extends VuexModule {
  bffService = new SIABffService();

  @Action
  getConsolidatedByProgramFilters(): Promise<ConsolidatedByProgramFiltersDTO> {
    return this.bffService
      .getConsolidatedByProgramFilters();
  }

  @Action
  getConsolidatedByProgramByYear(params: ConsolidatedByProgramByYearSearchParamsDTO): Promise<ConsolidatedByYearDTO | null> {
    return this.bffService
      .getConsolidatedByProgramByYear(params);
  }

  @Action
  getConsolidatedByProgramByMonth(params: ConsolidatedByProgramByMonthSearchParamsDTO): Promise<ConsolidatedByMonthDTO | null> {
    return this.bffService
      .getConsolidatedByProgramByMonth(params);
  }

  @Action
  getConsolidatedByProgramByDay(params: ConsolidatedByProgramByDaySearchParamsDTO): Promise<ConsolidatedByDayDTO | null> {
    return this.bffService
      .getConsolidatedByProgramByDay(params);
  }

  @Action
  getConsolidatedByProgramExcel(params: ConsolidatedByProgramExportParams): Promise<ArrayBuffer> {
    return this.bffService
      .getConsolidatedByProgramExcel(params);
  }
}
