
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UserAvatar extends Vue {
  get isSimulatingUser(): boolean {
    return this.$store.state.user.isSimulatingUser;
  }

  get currentUser(): string {
    if (!this.$store.state.user.currentUser) return 'Desconhecido';
    return this.$store.state.user.currentUser.name ? this.$store.state.user.currentUser.name : this.$store.state.user.currentUser.email;
  }
}
