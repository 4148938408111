import { Action, Module, VuexModule } from 'vuex-module-decorators';
import SIABffService from '@/services/sia-bff.service';
import {
  DailyAudienceDTO,
  DailyAudienceSearchParamsDTO,
  DailyAudienceFiltersDTO,
  DailyAudienceExportParamsDTO,
} from '@/data/dto/daily-audience.dto';
import {
  SimultaneousProgramsDTO,
  SimultaneousProgramsSearchParamsDTO,
} from '@/data/dto/simultaneous-programs.dto';
import {
  SearchProgramsDTO,
  SearchProgramsSearchParamsDTO,
} from '@/data/dto/search-programs.dto';
import {
  ProgramLastExhibitionDateDTO,
  ProgramLastExhibitionDateParamsDTO
} from "@/data/dto/program-last-exhibition-date.dto";

@Module
export default class DailyAudienceModule extends VuexModule {
  bffService = new SIABffService();

  @Action
  getDailyAudience(params: DailyAudienceSearchParamsDTO): Promise<DailyAudienceDTO | null> {
    return this.bffService
      .getDailyAudience(params);
  }

  @Action
  getDailyAudienceFilters(): Promise<DailyAudienceFiltersDTO> {
    return this.bffService
      .getDailyAudienceFilters();
  }

  @Action
  getSimultaneousPrograms(params: SimultaneousProgramsSearchParamsDTO): Promise<SimultaneousProgramsDTO> {
    return this.bffService
      .getSimultaneousPrograms(params);
  }

  @Action
  searchPrograms(params: SearchProgramsSearchParamsDTO): Promise<SearchProgramsDTO> {
    return this.bffService
      .searchPrograms(params);
  }

  @Action
  getProgramLastExhibitionDate(params: ProgramLastExhibitionDateParamsDTO): Promise<ProgramLastExhibitionDateDTO> {
    return this.bffService
      .getProgramLastExhibitionDate(params);
  }

  @Action
  getDailyAudienceExcel(params: DailyAudienceExportParamsDTO): Promise<ArrayBuffer> {
    return this.bffService
      .getDailyAudienceExcel(params);
  }

}
