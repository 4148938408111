import { Action, Module, VuexModule } from 'vuex-module-decorators';
import SIABffService from '@/services/sia-bff.service';
import { 
  FiltersByMarketSearchParams,
  FiltersByMarketResponse,
} from '@/data/dto/research-filter.dto';

@Module
export default class FiltersModule extends VuexModule {
  bffService = new SIABffService();

  @Action
  getFiltersByMarket(params: FiltersByMarketSearchParams): Promise<FiltersByMarketResponse> {
    return this.bffService
      .getFiltersByMarket(params);      
  }
}