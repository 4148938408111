import { Action, Module, VuexModule } from 'vuex-module-decorators';
import SIABffService from '@/services/sia-bff.service';

type CreateGroupPayload = {
  name: string;
  description: string;
  permissions: { key: string; value: string }[],
  hidden?: boolean,
};
type UpdateGroupPayload = {
  uuid: string;
  name: string;
  description: string;
  permissions: { key: string; value: string }[];
  hidden?: boolean,
}
type UpdateGroupStatus = { uuid: string; active: boolean }

@Module
export default class GroupModule extends VuexModule {
  bffService = new SIABffService();

  @Action
  getAllGroups() {
    return this.bffService
      .getAllGroups();
  }

  @Action
  getOneGroup(payload: { uuid: string }) {
    return this.bffService
      .getOneGroup(payload.uuid);
  }

  @Action
  createGroup(payload: CreateGroupPayload) {
    return this.bffService
      .createGroup(payload.name, payload.description, payload.permissions, payload.hidden);
  }

  @Action
  updateGroup(payload: UpdateGroupPayload) {
    return this.bffService
      .updateGroup(payload.uuid, payload.name, payload.description, payload.permissions, payload.hidden);
  }

  @Action
  updateGroupStatus(payload: UpdateGroupStatus) {
    return this.bffService
      .updateGroupStatus(payload.uuid, payload.active);
  }

  @Action
  deleteGroup(payload: UpdateGroupStatus) {
    return this.bffService
      .deleteGroup(payload.uuid);
  }

  @Action
  getAuthorizationConfigs() {
    return this.bffService
      .getAuthorizationConfigs();
  }

  @Action
  getUsersFromGroup(uuid: string) {
    return this.bffService
      .getUsersFromGroup(uuid);
  }

  @Action
  addMultipleUsersToApplicationGroup(payload: {uuid: string, users: string[]}) {
    return this.bffService
      .addMultipleUsersToApplicationGroup(payload.uuid, payload.users);
  }
}
