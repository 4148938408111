
import { Component, Vue } from 'vue-property-decorator';
import DatetimeUtil from '@/utils/datetime.util';
import { RouteConfig } from 'vue-router';
import UserAvatar from '@/components/UserAvatar.vue';
import { UserDTO } from '@/data/dto/user.dto';
import RouterUtil from '@/utils/router.util';
import UIUtil from '@/utils/ui.util';

@Component({
  components: {
    UserAvatar,
  },
})
export default class Menu extends Vue {
  showSidebar = false;
  currentDate:string = DatetimeUtil.longDate();
  routes: RouteConfig[] = [];

  get currentUser(): UserDTO {
    return this.$store.state.user.currentUser;
  }

  get appInitials(): string {
    return this.$store.state.system.appInitials;
  }

  get appTitle(): string {
    return this.$store.state.system.appTitle;
  }

  get sidebarIcon(): string {
    return this.showSidebar ? 'x' : 'list';
  }

  get isSimulatingUser(): boolean {
    return this.$store.state.user.isSimulatingUser;
  }

  get realtimeUrl(): string {
    return process.env.VUE_APP_REALTIME_URL;
  }

  get currentRouteIcon(): string {
    return this.$route.meta && this.$route.meta.imageFile ? this.$route.meta.imageFile : 'logo-sia.png';
  }

  generateGradient(): string {
    const customColors = this.$router.currentRoute.meta ? this.$router.currentRoute.meta.customColors : undefined;
    return UIUtil.generateGradient(customColors);
  }

  generateSrc(fileName: string): string {
    return UIUtil.generateImagePathbyFileName(fileName);
  }

  getChildPath(parent: RouteConfig, child: RouteConfig): string {
    return child.meta && child.meta.userAllowed ? `${parent.path}/${child.path}`: '';
  }

  routeLabel(route: RouteConfig): string {
    return route.meta && route.meta.label ? route.meta.label : route.name;
  }

  toogleSidebar(): void {
    this.showSidebar = !this.showSidebar;
  }

  async logout(): Promise<void> {
    if (this.isSimulatingUser) {
      await this.$store.dispatch('logoutSimulatedUser');
      await this.$router.push({ name: 'users' });
      this.$router.go(0);
      return;
    }
    await this.$store.dispatch('logout');
    this.$router.push({ name: 'logout'});
  }

  mounted(): void {
    this.routes = RouterUtil.getNavigationRoutes(this.$store.state.user.userRoutes);
  }
}
