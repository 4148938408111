import Vue from 'vue';
// bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import 'moment/locale/pt-br';
import moment from 'moment';

moment.locale('pt-br');

// highcharts
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
Highcharts.setOptions({
  lang: {
    months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    decimalPoint: ',',
    thousandsSep: '.',
  }
});
import HighchartsCustomEvents from 'highcharts-custom-events';
HighchartsCustomEvents(Highcharts);
import stockInit from 'highcharts/modules/stock';
stockInit(Highcharts);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(HighchartsVue);