import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '@/store';
import router from '@/router';

type ErrorHandlers = { [key:number]: () => void; }

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any>(config: AxiosRequestConfig): Promise<T>;
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  }
}

export abstract class HttpClient {
  protected readonly client: AxiosInstance;

  protected constructor(baseURL: string, headers?: any) {
    this.client = axios.create({ baseURL, headers });

    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.client.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    );

    this.client.interceptors.request.use(
      this.handleRequests,
      this.handleError,
    );
  }

  private handleRequests = (request: AxiosRequestConfig) => {
    request.headers.token = window.localStorage.getItem('simulated-token') || window.localStorage.getItem('token');
    store.commit('loadingStart');
    return request;
  }

  private handleResponse = ({ data }: AxiosResponse) => {
    store.commit('loadingEnd');
    return data;
  }

  protected handleError = (error: AxiosError) => {
    store.commit('loadingEnd');

    // Tratamentos para casos de erro específicos
    const errorHandlers: ErrorHandlers = {
      401: () => this.handleUnauthorized(error),
      403: this.handleForbidden,
    };

    if(error.response && Object.keys(errorHandlers).includes(error.response.status.toString())) {
      errorHandlers[error.response.status]();
    } else {
      store.commit('showAlert', { message: 'Ocorreu um erro', type: 'danger'});
    }

    return Promise.reject(error);
  }

  private handleUnauthorized = ({ response }: AxiosError) => {
    router.push({ name: 'login'});

    if (response?.data) {
      const { data: error } = response;
      if (error?.name === 'UnregisteredUserError') {
        store.commit('showAlert', {
          message: `
            Você ainda não tem acesso ao Novo SIA.
            <a href="${process.env.VUE_APP_SIA_REGISTER_REQUEST_URL}">
               Clique aqui para solicitar.
            </a>
          `,
          type: 'danger',
          timeout: 300,
        });
      }
    }
  }

  private handleForbidden = () => {
    store.commit('showAlert', { message: 'Você não tem permissão para acessar esse conteúdo', type: 'danger'});
  }
}
