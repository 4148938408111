import moment, { Moment } from 'moment';

export type AllowedDateTypes = string | number | Date | Moment;

export default class DatetimeUtil {

  static localizeDate(date?: AllowedDateTypes, unix?: boolean): Moment{
    if (!date) moment().locale('pt-br');
    return unix && typeof(date) === 'number' ? moment.unix(date).locale('pt-br') : moment(date).locale('pt-br');
  }

  static longDate(date?: AllowedDateTypes, unix?: boolean): string {
    const locDate = DatetimeUtil.localizeDate(date, unix);
    const weekDay = locDate.format('dddd'); 
    const fullDate = locDate.format('LL');
    return `${weekDay}, ${fullDate}`;
  }

  static shortDate(date?: AllowedDateTypes, unix?: boolean): string {
    const locDate = DatetimeUtil.localizeDate(date, unix);
    return locDate.format('L'); 
  }

  static shortDateTime(date?: AllowedDateTypes, unix?: boolean): string {
    const locDate = DatetimeUtil.localizeDate(date, unix);
    const shortDate = locDate.format('L');
    const shortTime = locDate.format('HH:mm')
    return `${shortDate} ${shortTime}`;
  }

  static weekday(date?: AllowedDateTypes, unix?: boolean): string {
    const locDate = DatetimeUtil.localizeDate(date, unix);
    return locDate.format('dddd'); 
  }

  static unixTime(date?: AllowedDateTypes): number {
    return DatetimeUtil.localizeDate(date).unix();
  }

  static stringDateDTOToView(date: string): string {
    if(!date) return '';
    return date.split('-').reverse().join('/');
  }

  static stringDateViewToDTO(date: string): string {
    if(!date) return '';
    return date.split('/').reverse().join('-');
  }

  static UTCTimestampToDTO(date: number): string {
    return moment(date).utc().format('YYYY-MM-DD');
  }

  static stringTimeToView(time: string): string {
    return time ? time.slice(0,5) : '';
  }

  static generateUTCDatetime(date: string, time:string, gridStart: number): number {
    try {
      let [year, month, day] = date.split('-').map(n => parseInt(n)); // eslint-disable-line prefer-const
      month--;
      const [hour, minute] = time.split(':').map(n => parseInt(n));
      if (hour < gridStart) day++;
      return Date.UTC(year, month, day, hour, minute);
    } catch(e) {
      console.log(e);
      return 0;
    }
  }
}